import React, { useEffect, useState } from "react";
import { fetchUserDetails } from "../Header/Data";

const ProductCard = ({ item }) => {
  const [user, setUser] = useState({});
  const item_desc = item.Description;
  const title = item.Title;
  let maxLength = 100;
  let truncateditem_desc = "";
  truncateditem_desc =
    item_desc.length > maxLength
      ? `${item_desc.slice(0, maxLength)}...`
      : item_desc;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = await fetchUserDetails(6);
        setUser(userData);
      } catch (error) {
        console.error("Error fetching allProducts:", error.message);
      }
    };
    fetchData();
  }, []);

  if (item.length === 0) {
    return <p>Loading...</p>;
  }
  return (
    <div className="product-card card my-3" style={{ maxHeight: "200px" }}>
      <div className="p-3 card-container">
        {user ? (
          <h5 className="m-0 text-center" style={{ width: "auto" }}>
            {title.length > 50 ? `${title.substring(0, 50)}...` : title}
          </h5>
        ) : (
          <h5 className="pt-1 text-center">My SikhCommunity</h5>
        )}
        <div className="text-center">
          <a
            href={`tel:8002453610`}
            style={{
              backgroundColor: "#F5F6FF",
              padding: "5px 10px ",
              borderRadius: "5px",
              fontSize: "small",
              width: "auto",
            }}
            className="my-1"
          >
            Call: (800) 245-3610
            <br />
            Extenstion: {item?.dialExtension}
          </a>
          <p
            style={{
              fontSize: "12px",
              color: "black",
              width: "auto",
              height: "100px",
              overflow: "",
            }}
            className="my-2"
          >
            {truncateditem_desc}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
