import React, { useEffect, useState } from "react";


const Pricing = () => {

  return (
   <>
   <div className="py-5 bg-info mb-5">
   <div className="container py-5">
    <div className="row">
        <h1 className="text-center"><b>PRICING</b></h1>
    </div>
   </div>
   </div>
   <div className="pricing-content mb-5">
    <div className="container">
        <h1 >1.Pricing</h1>
        <p className="text-dark pt-4">The site is Free for customers to visit and browse for vendors.</p>
        <p className="text-dark">The site is Free for vendors to list their service offerings</p>
        <p className="text-dark">Vendors do not pay any transaction fees. </p>
        <p className="text-dark py-4">MySikhCommunity.ORG reserves the right to update any service terms with adequate notification. </p>
    </div>
   </div>
   </>
  );
};

export default Pricing;
