import React from "react";
import UsersList from "./UsersList";
import UsersMap from "./UsersMap";
import { useSelector } from "react-redux";
import { Col, Row, Button } from "reactstrap";
import { FadeLoader } from "react-spinners"; // Import FadeLoader

const UsersContainer = () => {
  const { userMaps, isLoading } = useSelector((state) => state.peopleInCom);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <FadeLoader color={"#36D7B7"} loading={isLoading} size={50} />
      </div>
    );
  }

  return (
    <div>
      <Row className="d-none d-md-flex">
        <Col xs={12} md={userMaps ? 6 : 12}>
          <UsersList />
        </Col>
        {userMaps && (
          <Col xs={12} md={6}>
            <UsersMap />
          </Col>
        )}
      </Row>
      {userMaps && (
        <Row className="d-block d-md-none">
          <Col xs={12}>
            <UsersMap />
          </Col>
          <Col xs={12}>
            <UsersList />
          </Col>
        </Row>
      )}
      {!userMaps && (
        <Row className="d-block d-md-none">
          <Col xs={12}>
            <UsersList />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default UsersContainer;
