import React, { useEffect, useState } from "react";
import NeedCategories from "./NeedCategories";
import { Row, Form, Col, FormGroup, Label, Input, Button } from "reactstrap";
import NeedPhotos from "./NeedPhotos";
import { useSelector, useDispatch } from "react-redux";
import { fetchGurdwaras } from "../../../components/Header/Data";
import {
  setTitle,
  setGurudwara,
  setDescription,
  setZipcode,
  setPhone,
  setDialExtension,
  setTerms,
  setAlternativePhoneOne,
  setAlternativePhoneTwo,
} from "../../../redux/slices/RequestFormSlice";
const CreateNeedVersion2 = ({
  categories,
  subCategories1,
  subCategories,
  setSubCategories,
  handleFormSubmit,
  selectedCat,
  setSelectedCat,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [gurdwaras, setGurdwaras] = useState([]);
  const formData = useSelector((state) => state.requestForm);
  const isAdmin = user && user.Role === "admin"; // Check if user is admin

  const fetchData = async () => {
    const gurudwaras = await fetchGurdwaras();
    setGurdwaras(gurudwaras);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Form className="wc_create-request" onSubmit={handleFormSubmit}>
      <Col className="request-form p-2 p-lg-4 mt-4">
        <p className="text-center">Need/Request</p>
        <NeedCategories
          categories={categories}
          subCategories1={subCategories1}
          subCategories={subCategories}
          setSubCategories={setSubCategories}
          selectedCat={selectedCat}
          setSelectedCat={setSelectedCat}
        />
        <Row>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label>
                Enter Ad Title <b className="text-danger">*</b>
              </Label>
              <Input
                type="text"
                name="Title"
                placeholder="Ex: Seeking an event Decorator great with Flowers"
                value={formData.Title}
                onChange={(e) => dispatch(setTitle(e.target.value))}
              />
            </FormGroup>
          </Col>
          <Col xs="12" sm="6">
            <FormGroup>
              <Label>Select Gurdwara Near You</Label>
              <Input
                type="select"
                name="Gurudwara"
                //placeholder="Enter Zip code "
                onChange={(e) => dispatch(setGurudwara(e.target.value))}
                value={formData.Gurudwara}
              >
                <option value="" selected>
                  Select Gurdwara
                </option>
                {gurdwaras.map((gurdwara) => (
                  <option key={gurdwara.id} value={gurdwara.name}>
                    {gurdwara.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <FormGroup>
            <Label>AD Description. {/*Mention Contact Phone Number!*/}</Label>
            <Input
              type="textarea"
              name="Description"
              maxLength="200"
              placeholder={
                formData.engagedCouple === 1
                  ? "Expectations & Requirements from the vendors.."
                  : "Write details what your AD is about.."
              }
              value={formData.Description}
              onChange={(e) => {
                if (e.target.value.length <= 200) {
                  dispatch(setDescription(e.target.value));
                }
              }}
            />
          </FormGroup>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                Enter Zip Code
                <b className="text-danger">*</b>
              </Label>
              <Input
                type="text"
                name="Zipcode"
                placeholder="Enter Zip code "
                onChange={(e) => {
                  const { value } = e.target;
                  const isValidZipCode = /^\d{0,5}$/.test(value); // Ensure up to 5 digits

                  if (isValidZipCode) {
                    dispatch(setZipcode(value));
                  }
                }}
                value={formData.Zipcode}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <div className="col-lg-3 phone">
            <FormGroup>
              <Label>
                Mobile Number <b className="text-danger">*</b>
              </Label>
              <Input
                type="number"
                name="Phone"
                value={formData.Phone}
                onChange={(e) => {
                  const { value } = e.target;
                  const isValidPhone = /^\d{0,10}$/.test(value); // Ensure up to 10 digits
                  if (isValidPhone) {
                    dispatch(setPhone(value));
                  }
                }}
              />
            </FormGroup>
          </div>
          <div className="col-lg-3 alterPhone-1">
            <FormGroup>
              <Label> Mobile Number (family member)</Label>
              <Input
                type="number"
                name="alternativePhoneOne"
                value={formData.alternativePhoneOne}
                onChange={(e) => {
                  const { value } = e.target;
                  const isValidPhone = /^\d{0,10}$/.test(value); // Ensure up to 10 digits
                  if (isValidPhone) {
                    dispatch(setAlternativePhoneOne(value));
                  }
                }}
              />
            </FormGroup>
          </div>
          <div className="col-lg-3 alterPhone-2">
            <FormGroup>
              <Label> Mobile Number (family member)</Label>
              <Input
                type="number"
                name="alternativePhoneTwo"
                value={formData.alternativePhoneTwo}
                onChange={(e) => {
                  const { value } = e.target;
                  const isValidPhone = /^\d{0,10}$/.test(value); // Ensure up to 10 digits
                  if (isValidPhone) {
                    dispatch(setAlternativePhoneTwo(value));
                  }
                }}
              />
            </FormGroup>
          </div>
          <div className="col-lg-3 diel-ext">
            {isAdmin && (
              <FormGroup>
                <Label>
                  Dial Extension <b className="text-danger">*</b>
                </Label>
                <Input
                  type="text"
                  name="dialExtension"
                  value={formData.dialExtension}
                  onChange={(e) => {
                    const { value } = e.target;
                    const isValid = /^\d{0,5}$/.test(value);
                    if (isValid) {
                      dispatch(setDialExtension(value));
                    }
                  }}
                  disabled={user?.RoleId === 1 ? false : true}
                />
              </FormGroup>
            )}
          </div>
        </Row>
      </Col>
      <Col className="request-form p-4 mt-5">
        <h5 className="pb-3">
          Terms <b className="text-danger">*</b>
        </h5>
        <h6 className="fst-italic pb-3 small">
          <p className="text-justify pb-1">
            1.Please note that MySikhCommunity.ORG & Gurdwara Sahib assume no
            responsibility for the results of your interactions with
            advertisers.
          </p>
          <p className="text-justify pb-2 px-2">
            Additionally, by choosing to make your AD public on the community
            board, you agree to freely disclose your AD data to organizations or
            individuals
          </p>
          <p className="text-justify">
            2.If you wish to have the AD posted in English & Punjabi, then
            please call us. We will type & print the AD for you in both
            languages. (800)245-3610.
          </p>
        </h6>
        <div class="mb-3 form-check">
          <FormGroup>
            <Input
              type="checkbox"
              name="Terms"
              checked={formData.Terms === 1}
              onChange={(e) => {
                dispatch(setTerms(e.target.checked ? 1 : 0));
              }}
              class="form-check-input"
              id="exampleCheck1"
            />
            <Label class="form-check-label ps-2" for="exampleCheck1" required>
              I Understand And Accept Terms
            </Label>
          </FormGroup>
        </div>
      </Col>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          padding: "10px 2px 10px 10px",
        }}
      >
        <Button
          type="submit"
          className="btn btn-danger mb-2"
          disabled={isLoading}
        >
          Submit Request
        </Button>
      </div>
    </Form>
  );
};

export default CreateNeedVersion2;
