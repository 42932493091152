import {
  Alert,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import userIcon from "../../../assets/images/user-icon.png";
import axiosClient from "../../../axios-client";
import { toast } from "react-toastify";
const ConnectionRequestModal = ({ open, toggle, userDetails }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const dispatch = useDispatch();
  const [typeOfConnection, setTypeOfConnection] = React.useState("");
  const [msg, setMsg] = React.useState("");
  const toggleModal = () => {
    toggle();
    setTypeOfConnection("");
    setMsg("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const req = {
      Type: typeOfConnection,
      userId: userDetails.Id,
      createdBy: user.UserID,
      Comment: msg,
      connectionStatus: 0,
    };
    try {
      const response = await axiosClient.post("message/CreateConnection", req);
      if (response.status === 200) {
        toast.success(
          `Connection Sent To ${
            userDetails.FirstName || userDetails.LastName
              ? `${userDetails.FirstName || ""} ${
                  userDetails.LastName || ""
                }`.trim()
              : userDetails.UserName
          }`
        );
        toggleModal();
      }
    } catch (error) {
      toast.error("Failed Sending Connection");
    }
  };
  const connectionTypes = [
    "Friend",
    "Significant Partner",
    "Family Member",
    "Business Acquaintance",
  ];

  const handleChange = (event) => {
    setTypeOfConnection(event.target.value);
  };

  return (
    <div>
      <Modal isOpen={open} toggle={toggleModal}>
        <Form onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>
            Request To Connect{" "}
            {userDetails.FirstName || userDetails.LastName
              ? `${userDetails.FirstName || ""} ${
                  userDetails.LastName || ""
                }`.trim()
              : userDetails.UserName}
          </ModalHeader>
          <ModalBody>
            <Box sx={{ minWidth: 70, marginBottom: "10px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Connection Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={typeOfConnection}
                  label="Connection Type"
                  onChange={handleChange}
                  required={true}
                  fullWidth
                  size="small"
                  color="secondary"
                  placeholder="Connection Type"
                  sx={{
                    color: "black",
                  }}
                >
                  {connectionTypes.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ minWidth: 70, marginBottom: "10px" }}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  label="Leave A Message"
                  variant="outlined"
                  name="msg"
                  value={msg}
                  fullWidth
                  multiline
                  rows={4}
                  onChange={(e) => setMsg(e.target.value)}
                />
              </FormControl>
            </Box>
            {/* <Box>
              <Alert
                severity="info"
                style={{
                  maxWidth: "500px",
                  margin: "0 auto",
                }}
              >
                Request Sent
              </Alert>
            </Box> */}
          </ModalBody>
          <ModalFooter>
            <Button color="primary">Connect</Button>{" "}
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default ConnectionRequestModal;
