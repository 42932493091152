import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Name: "",
  Description: "",
  Quantity: 1,
  Price: null,
  Image: "",
  deliveryOption: "",
  createdEmail: "",
  itemStatus: 0,
  Address: "",
};

const IteamFormSlice = createSlice({
  name: "ifsForm",
  initialState,
  reducers: {
    resetItem: (state) => {
      state.Name = "";
      state.Description = "";
      state.Quantity = 1;
      state.Price = null;
      state.Image = "";
      state.deliveryOption = "";
      state.createdEmail = "";
      state.itemStatus = 0;
      state.Address = "";
    },
    setName: (state, action) => {
      state.Name = action.payload;
    },
    setDescription: (state, action) => {
      state.Description = action.payload;
    },
    setQuantity: (state, action) => {
      state.Quantity = action.payload;
    },
    setPrice: (state, action) => {
      state.Price = action.payload;
    },
    setImage: (state, action) => {
      state.Image = action.payload;
    },
    setDeliveryOption: (state, action) => {
      state.deliveryOption = action.payload;
    },
    setCreatedEmail: (state, action) => {
      state.createdEmail = action.payload;
    },

    setItemStatus: (state, action) => {
      state.itemStatus = action.payload;
    },

    setAddress: (state, action) => {
      state.Address = action.payload;
    },
  },
});

export const {
  resetItem,
  setName,
  setDescription,
  setQuantity,
  setPrice,
  setImage,
  setDeliveryOption,
  setCreatedEmail,
  setItemStatus,
  setAddress,
} = IteamFormSlice.actions;
export default IteamFormSlice.reducer;
