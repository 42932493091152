import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  convos: [],
  selectedConvo: null,
  messages: [],
  loadingNotif: false,
  myConnections: [],
  myJobOffers: [],
  selectedFile: null,
  lodingConvo: false,
  sendingFile: false,
};

const MessagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    resetMessagesState: (state) => initialState,
    setConversations: (state, action) => {
      state.convos = action.payload;
    },
    setSelectedConversation: (state, action) => {
      state.selectedConvo = action.payload;
    },

    setMessages: (state, action) => {
      state.messages = action.payload;
    },

    setLoadingNotif: (state, action) => {
      state.loadingNotif = action.payload;
    },

    setMyConnections: (state, action) => {
      state.myConnections = action.payload;
    },

    setMyJobOffers: (state, action) => {
      state.myJobOffers = action.payload;
    },

    setSelectedFile: (state, action) => {
      state.selectedFile = action.payload;
    },

    setLodingConvo: (state, action) => {
      state.lodingConvo = action.payload;
    },

    setSendingFile: (state, action) => {
      state.sendingFile = action.payload;
    },
  },
});

export const {
  resetMessagesState,
  setConversations,
  setSelectedConversation,
  setMessages,
  setLoadingNotif,
  setMyJobOffers,
  setMyConnections,
  setSelectedFile,
  setLodingConvo,
  setSendingFile,
} = MessagesSlice.actions;
export default MessagesSlice.reducer;
