import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../axios-client";

const getAllServices = createAsyncThunk("services", async () => {
  const response = await axiosClient.get("services/ListAllServices");
  return response.data.Data; // Return only the data property
});

const initialState = {
  loading: false,
  error: null,
  bwvData: [],
};
const bwvSlice = createSlice({
  name: "bwv",
  initialState,
  reducers: {},
  extraReducers: {
    [getAllServices.pending]: (state) => {
      state.loading = true;
    },
    [getAllServices.fulfilled]: (state, action) => {
      state.loading = false;
      state.bwvData = action.payload;
    },
    [getAllServices.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
console.log("initialState", initialState);
console.log("getInitialState", bwvSlice.getInitialState());

export default bwvSlice.reducer;

export const bwvActions = bwvSlice.actions;

export const getBwvdata = () => {
  return async (dispatch) => {
    await dispatch(getAllServices());
  };
};
