export const getAmount = (price, deliveryOption) => {
  let deliveryFee = 0;

  switch (deliveryOption) {
    case "1":
      deliveryFee = 5;
      break;
    case "2":
      deliveryFee = 10;
      break;
    case "3":
      deliveryFee = 0;
      break;
    default:
      deliveryFee = 0;
      break;
  }

  return Number(price) + Number(deliveryFee);
};
export const ItemShipping = (deliveryOption) => {
  let deliveryFee = 0;

  switch (deliveryOption) {
    case "1":
      deliveryFee = 5;
      break;
    case "2":
      deliveryFee = 10;
      break;
    case "3":
      deliveryFee = 0;
      break;
    default:
      deliveryFee = 0;
      break;
  }

  return Number(deliveryFee);
};
