import React from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmail,
  setFullName,
  setMobileNumber1,
  setMobileNumber2,
  setMobileNumber3,
} from "../../../../redux/slices/GurdwaraSlice";

const BookGurdwaraStep1 = () => {
  const dispatch = useDispatch();
  const {
    selectedServiceType,
    mobileNumber1,
    mobileNumber2,
    mobileNumber3,
    fullName,
    email,
  } = useSelector((state) => state.gurdwara);
  console.log(selectedServiceType, "selectedServiceType");
  return (
    // <div>
    //   <div className="d-flex align-items-center justify-content-center">
    //     <div
    //       className="page1"
    //       style={{
    //         height: currentStep === 3 || currentStep === 4 ? "750px" : "405px",
    //       }}
    //     >
    //       <div className="d-flex justify-content-between mx-5 my-4">
    //         <div className="fw-bold fs-5 text-decoration-underline">
    //           Section1
    //         </div>
    //         <button
    //           className="btn btn-primary"
    //           onClick={() => setCurrentStep((prev) => prev + 1)}
    //         >
    //           Next
    //         </button>
    //       </div>
    <div className="mx-5 my-4">
      <Row>
        <Label>
          Your Full Name: <b className="text-danger">*</b>
        </Label>
        <Input
          type="text"
          name="name"
          id="name"
          style={{ width: "98%", marginLeft: "1%" }}
          value={fullName}
          onChange={(e) => dispatch(setFullName(e.target.value))}
        />
      </Row>
      <Row className="mt-3">
        <Col>
          <Label>
            Email<b className="text-danger">*</b>
          </Label>{" "}
          <Input
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => dispatch(setEmail(e.target.value))}
          />
        </Col>
      </Row>
      {/* <Row className="mt-3">
        <Col>
          <Label>
            Service Type <b className="text-danger">*</b>
          </Label>
          <Input
            className="mb-3"
            type="select"
            value={selectedServiceType}
            onChange={handleServiceChange}
          >
            <option value="0">Select service type here</option>
            {serviceTypes.map((serviceType, index) => (
              <option key={index} value={serviceType}>
                {serviceType}
              </option>
            ))}
          </Input>
        </Col>
      </Row> */}
      <Row className="mt-3">
        <Col sm={4}>
          <Label>
            Mobile Number 1 <b className="text-danger">*</b>
          </Label>
          <Input
            type="number"
            name="mobileNumber1"
            id="mobileNumber1"
            value={mobileNumber1}
            onChange={(e) => {
              const { value } = e.target;
              const isValidPhone = /^\d{0,10}$/.test(value); // Ensure up to 10 digits
              if (isValidPhone) {
                dispatch(setMobileNumber1(e.target.value));
              }
            }}
          />
        </Col>
        <Col sm={4}>
          <Label>Mobile Number 2{/* <b className="text-danger">*</b> */}</Label>
          <Input
            type="number"
            name="mobileNumber2"
            id="mobileNumber2"
            value={mobileNumber2}
            onChange={(e) => {
              const { value } = e.target;
              const isValidPhone = /^\d{0,10}$/.test(value); // Ensure up to 10 digits
              if (isValidPhone) {
                dispatch(setMobileNumber2(e.target.value));
              }
            }}
          />
        </Col>
        <Col sm={4}>
          <Label>Mobile Number 3{/* <b className="text-danger">*</b> */}</Label>
          <Input
            type="number"
            name="mobileNumber3"
            id="mobileNumber3"
            value={mobileNumber3}
            onChange={(e) => {
              const { value } = e.target;
              const isValidPhone = /^\d{0,10}$/.test(value); // Ensure up to 10 digits
              if (isValidPhone) {
                dispatch(setMobileNumber3(e.target.value));
              }
            }}
          />
        </Col>
      </Row>
    </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default BookGurdwaraStep1;
