import React, { useState } from "react";
import "../NeedPages/Request.css";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import ActiveNeeds from "./ActiveNeeds";
import InActiveNeeds from "./InActiveNeeds";
import ExpireOrPauseNeeds from "./PauseNeeds";

const AllNeeds = ({ allNeeds, fetchData }) => {
  const [activeNeedsExpanded, setActiveNeedsExpanded] = useState(false);
  const [pendingNeedsExpanded, setpendingNeedsExpanded] = useState(false);
  const [expiredOrPauseNeedsExpanded, setexpiredOrPauseNeedsExpanded] =
    useState(false);
  const activeNeeds = allNeeds.filter((need) => need.Status === 1);
  const pendingNeeds = allNeeds.filter((need) => need.Status === 0);
  const expiredOrPauseNeeds = allNeeds.filter((need) => need.Status === 3);

  return (
    <div className="category-container d-flex flex-column gap-2 inter-font">
      <div
        className="category-header"
        onClick={() => setActiveNeedsExpanded(!activeNeedsExpanded)}
      >
        <h6
          className={`ps-3 d-flex justify-content-between p-3 rounded border border-secondary text-secondary bg-outline-secondary ${
            activeNeedsExpanded ? "active text-white bg-secondary" : ""
          }`}
          style={{ cursor: "pointer", transition: "all 0.2s ease-in-out" }}
        >
          Active{" "}
          {activeNeedsExpanded ? (
            <FaAngleUp size={20} />
          ) : (
            <FaAngleDown size={20} />
          )}
        </h6>
      </div>
      {activeNeedsExpanded && (
        <>
          {activeNeeds.length > 0 ? (
            <ActiveNeeds activeNeeds={activeNeeds} fetchData={fetchData} />
          ) : (
            <h6 className="text-center m-5">No Active needs</h6>
          )}
        </>
      )}

      <div
        className="category-header"
        onClick={() => setpendingNeedsExpanded(!pendingNeedsExpanded)}
      >
        <h6
          className={`ps-3 d-flex justify-content-between p-3 rounded border border-secondary text-secondary bg-outline-secondary ${
            pendingNeedsExpanded ? "active text-white bg-secondary" : ""
          }`}
          style={{ cursor: "pointer", transition: "all 0.2s ease-in-out" }}
        >
          Pending {pendingNeedsExpanded ? <FaAngleUp /> : <FaAngleDown />}
        </h6>
      </div>
      {pendingNeedsExpanded && (
        <>
          {pendingNeeds.length > 0 ? (
            <InActiveNeeds inactiveNeeds={pendingNeeds} fetchData={fetchData} />
          ) : (
            <h6 className="text-center m-5">No Pending needs</h6>
          )}
        </>
      )}

      <div
        className="category-header"
        onClick={() =>
          setexpiredOrPauseNeedsExpanded(!expiredOrPauseNeedsExpanded)
        }
      >
        <h6
          className={`ps-3 d-flex justify-content-between p-3 rounded border border-secondary text-secondary bg-outline-secondary ${
            expiredOrPauseNeedsExpanded ? "active text-white bg-secondary" : ""
          }`}
          style={{ cursor: "pointer", transition: "all .2s ease-in-out" }}
        >
          Expired/Paused{" "}
          {expiredOrPauseNeedsExpanded ? <FaAngleUp /> : <FaAngleDown />}{" "}
        </h6>
      </div>
      {expiredOrPauseNeedsExpanded && (
        <>
          {expiredOrPauseNeeds.length > 0 ? (
            <ExpireOrPauseNeeds
              expiredOrPauseNeeds={expiredOrPauseNeeds}
              fetchData={fetchData}
            />
          ) : (
            <h6 className="text-center m-5">No Expired/Paused needs</h6>
          )}
        </>
      )}
    </div>
  );
};

export default AllNeeds;
