import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import "./peopleProfile.css";
import { useParams } from "react-router-dom";
import { fetchUserDetails } from "../../components/Header/Data";
import PeopleProfileItems from "./PeopleProfileItems";
import PeopleProfileDetails from "./PeopleProfileDetails";
import MsgModal from "../../components/Header/modals/MsgModal";
import ConnectionRequestModal from "../../components/Header/modals/ConnectionRequestModal";
import { useDispatch, useSelector } from "react-redux";
import { setConnectionModal, setOpenJobModal } from "../../redux/slices/PeopleInComSlice";
import SendJobModal from "../../components/Header/modals/SendJobModal";

const PeopleProfile = () => {
  const dispatch = useDispatch();
  const { Id: userId } = useParams();
  const [userDetails, setUserDetails] = React.useState({});
  const [msgModalOpen, setMsgModalOpen] = React.useState(false);
  const { connectionModal, openJobModal } = useSelector(
    (state) => state.peopleInCom
  );

  const toggleMsgModal = () => {
    setMsgModalOpen(!msgModalOpen);
  };

  const toggleConnectionModal = () => {
    dispatch(setConnectionModal(!connectionModal));
  };

  const toggleJobModal = () => {
    dispatch(setOpenJobModal(!openJobModal));
  };

  const getDetails = async () => {
    const userData = await fetchUserDetails(userId);
    setUserDetails(userData);
  };

  useEffect(() => {
    getDetails();
  }, [userId]);

  return (
    <div className="people-profile-container">
      <Row className="">
        <Col lg={4} xl={4} md={4} sm={12} xs={12}>
          <PeopleProfileDetails
            userDetails={userDetails}
            setMsgModalOpen={setMsgModalOpen}
          />
        </Col>
        {/* Divider between PeopleProfileDetails and PeopleProfileItems */}
        <Col lg={8} xl={8} md={8} sm={12} xs={12}>
          <PeopleProfileItems userDetails={userDetails} userId={userId} />
        </Col>
      </Row>
      <MsgModal
        isOpen={msgModalOpen}
        closeModal={toggleMsgModal}
        userDetails={userDetails}
      />
      <ConnectionRequestModal
        open={connectionModal}
        toggle={toggleConnectionModal}
        userDetails={userDetails}
      />
      <SendJobModal
        open={openJobModal}
        toggle={toggleJobModal}
        userDetails={userDetails}
      />
    </div>
  );
};

export default PeopleProfile;
