import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  Button,
  Form,
} from "reactstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { listGurudwaras, updateProfile } from "../../components/Header/Data";

const UpdateUserModal = ({ isOpen, closeModal, userInfo }) => {
  const [newDetails, setNewDetails] = useState({
    FirstName: userInfo?.FirstName || "",
    LastName: userInfo?.LastName || "",
    UserName: userInfo?.UserName || "",
    Email: userInfo?.Email || "",
    Phone: userInfo?.MobileNo || "",
    Gurudwara: userInfo?.Gurudwara || "",
  });
  const [gurdwaras, setGurdwaras] = useState([]);
  const fetchData = async () => {
    const gurudwaras = await listGurudwaras();
    setGurdwaras(gurudwaras);
  };
  console.log(userInfo, "userInfo");
  useEffect(() => {
    fetchData();
  }, []);
  // Update newDetails when userInfo changes
  useEffect(() => {
    setNewDetails({
      FirstName: userInfo?.FirstName || "",
      LastName: userInfo?.LastName || "",
      UserName: userInfo?.UserName || "",
      Email: userInfo?.Email || "",
      Phone: userInfo?.MobileNo || "",
      Gurudwara: userInfo?.Gurudwara || "",
    });
  }, [userInfo]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDetails({
      ...newDetails,
      [name]: parseInt(value),
    });
  };

  const handleSubmit = async () => {
    await updateProfile(newDetails, userInfo.Id);
    closeModal();
    toast.success(`${newDetails.UserName} Profile Updated`);
  };

  return (
    <Modal isOpen={isOpen} toggle={closeModal} className="user-update-modal">
      <ModalBody>
        <button
          type="button"
          className="btn-close position-absolute top-0 end-0 m-2"
          onClick={closeModal}
          aria-label="Close"
        ></button>
        <div className="modal-body">
          <div>
            <Form>
              <Row className="mt-3">
                <Col>
                  <Label className="">First Name</Label>
                  <Input
                    type="text"
                    name="FirstName"
                    placeholder="First Name"
                    className="form-control"
                    value={newDetails.FirstName}
                    onChange={handleInputChange}
                  />
                </Col>
                <Col>
                  <Label className="">Last Name</Label>
                  <Input
                    type="text"
                    name="LastName"
                    placeholder="Last Name"
                    className="form-control"
                    value={newDetails.LastName}
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={4}>
                  <Label className="">User Name</Label>
                  <Input
                    type="text"
                    name="UserName"
                    placeholder="User Name"
                    className="form-control"
                    value={newDetails.UserName}
                    onChange={handleInputChange}
                  />
                </Col>
                <Col lg={8}>
                  <Label className="">Email</Label>
                  <Input
                    type="email"
                    name="Email"
                    placeholder="Enter Email"
                    className="form-control"
                    value={newDetails.Email}
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Label className="">Mobile</Label>
                  <Input
                    type="number"
                    name="Phone"
                    placeholder="Mobile"
                    className="form-control"
                    value={newDetails.Phone}
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Label className="">Select Gurdwara</Label>
                  <Input
                    type="select"
                    name="Gurudwara"
                    placeholder="Mobile"
                    className="form-control"
                    value={newDetails.Gurudwara}
                    onChange={handleInputChange}
                  >
                    <option value="0">Select a category</option>
                    {gurdwaras.map((gurudwara) => (
                      <option key={gurudwara.Id} value={gurudwara.Id}>
                        {gurudwara.Title}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <Button color="primary" onClick={handleSubmit}>
              Update
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UpdateUserModal;
