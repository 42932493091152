import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Col } from "reactstrap";
import userIcon from "../../assets/images/ext/user-profile.jpg";
import { FaShoppingCart } from "react-icons/fa";
import AddToCartModal from "./AddToCartModal";
import ConfirmRemoveModal from "./ConfirmRemoveModal"; // Import the confirmation modal
import "./itemCard.css";
import axiosClient from "../../axios-client";
import { useSelector } from "react-redux";

export default function ItemCard({ profileItems, setProfileItems, getAllIfs }) {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [myCart, setMyCart] = useState([]);
  const [alertModal, setAlertModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false); // State for the confirmation modal

  const myCartItems = async () => {
    try {
      const response = await axiosClient.get(`item/getCartItem/${user.UserID}`);
      if (response.status === 200) {
        setMyCart(response.data.cartList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    myCartItems();
  }, []);
  useEffect(() => {
    if (profileItems && myCart) {
      // Update the profileItems directly
      const updatedItems = profileItems.map((item) => {
        if (myCart.some((cartItem) => cartItem.item_id === item.Id)) {
          return { ...item, isAdded: true };
        }
        return item;
      });
      setProfileItems(updatedItems); // Trigger re-render by updating the state
    }
  }, [profileItems, myCart, openModal]);

  const toggle = () => setOpenModal(!openModal);
  const toggleAlert = () => setAlertModal(!alertModal);
  const toggleConfirm = () => setConfirmModal(!confirmModal);

  const handleAdd = (item) => {
    console.log(item, "myCart");
    console.log(myCart, "myCart");
    if (
      myCart.length > 0 &&
      myCart.some((cartItem) => cartItem.created_by !== item.created_by)
    ) {
      setSelectedItem(item);
      toggleConfirm(); // Open the confirmation modal if different user items are in the cart
    } else {
      setSelectedItem(item);
      toggle(); // Open AddToCartModal if same user or cart is empty
    }
  };

  const handleConfirmRemove = async () => {
    const request = {
      cartStatus: 2,
    };
    try {
      const response = await axiosClient.put(
        `item/StatusUpdateCart/${user.UserID}`,
        request
      );
      if (response.status === 200) {
        myCartItems();
        toggleAlert();
        setMyCart([]); // Clear the cart state
        toggleConfirm(); // Close the confirmation modal
        toggle(); // Open the AddToCartModal after clearing the cart
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {profileItems &&
        profileItems.map((item) => (
          <Card
            sx={{ maxWidth: 150, minWidth: 150, margin: "0 10px" }}
            key={item.Id}
            style={{
              cursor: "pointer",
            }}
          >
            <CardMedia
              component="img"
              height="140"
              image={item.image && item.image !== "" ? item.image : userIcon}
              alt={item.name}
            />
            <CardContent className="p-2">
              <Typography gutterBottom variant="body1" component="div" noWrap>
                {item.name}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", marginBottom: "10px" }}
              >
                ${item.price}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  color: item.isAdded ? "#fff" : "black",
                  display: "flex",
                  alignItems: "center",
                  padding: "6px 10px",
                  fontSize: "0.875rem",
                  borderRadius: "4px",
                }}
                disabled={item.isAdded} // Disable the button if the item is already in the cart
                startIcon={<FaShoppingCart style={{ fontSize: "1rem" }} />}
                fullWidth
                className={`d-inline ${
                  item.isAdded
                    ? `bg-success text-white`
                    : `bg-warning text-black `
                }`}
                onClick={() => handleAdd(item)}
              >
                {item.isAdded ? "Added" : "Buy Now"}
              </Button>
            </CardContent>
          </Card>
        ))}
      {/* AddToCartModal */}
      <AddToCartModal
        open={openModal}
        toggle={toggle}
        selectedItem={selectedItem}
        // myCart={myCart}
        getAllIfs={getAllIfs}
        myCartItems={myCartItems}
      />

      <ConfirmRemoveModal
        open={confirmModal}
        toggle={toggleConfirm}
        onConfirm={handleConfirmRemove}
      />
    </>
  );
}
