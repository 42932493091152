import React, { useEffect, useState } from "react";
import Sidebar from "../../SideBar";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import { BiPlusCircle, BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ManageGurdwara from "./ManageGurdwara";
import {
  detailsGurudwara,
  fetchGurdwaraBookingAdmin,
  fetchGurudwaraBooking,
} from "../../../components/Header/Data";
import BookingList from "./BookingList";

const MyGurudwaraPage = () => {
  const [activeTab, setActiveTab] = useState("ManageGurdwara");
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const id = user?.Gurudwara;
  const [isLoading, setIsLoading] = useState(false);
  const [gurdwara, setGurdwara] = useState([]);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [years, setYears] = useState([]); // State for unique years
  const [serviceTypes, setServiceTypes] = useState([]);
  const { responseId } = useSelector((state) => state.gurdwara);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [filteredBookingDetails, setFilteredBookingDetails] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState("");
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const fetchDetailsGurdwara = async () => {
    setIsLoading(true);
    if (!isAdmin) {
      const response = await detailsGurudwara(id);
      setGurdwara(response.Data);
    }
    setIsLoading(false);
  };

  const fetchBookingDetails = async () => {
    if (isAdmin) {
      const bookingDetails = await fetchGurdwaraBookingAdmin();
      console.log(bookingDetails, "bookingDetails");
      setBookingDetails(Array.isArray(bookingDetails) ? bookingDetails : []);
    } else {
      // const ID = user?.UserID;
      // const formData = {
      //   gurudwaraId: id,
      // };
      const bookingDetails = await fetchGurudwaraBooking(id);
      console.log(bookingDetails, "bookingDetails");
      setBookingDetails(Array.isArray(bookingDetails) ? bookingDetails : []);
    }
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleServiceTypeChange = (event) => {
    setSelectedServiceType(event.target.value);
  };
  const extractUniqueYearsAndServiceTypes = () => {
    const uniqueYears = new Set();
    const uniqueServiceTypes = new Set();

    bookingDetails.forEach((booking) => {
      const { eventDate, serviceType } = booking;

      // Extract year from eventDate
      if (eventDate && typeof eventDate === "string") {
        const year = eventDate.split("-")[0]; // Get the year part
        uniqueYears.add(year); // Add to set
      }

      // Add serviceType to set
      if (serviceType) {
        uniqueServiceTypes.add(serviceType);
      }
    });

    // Update state with unique values
    setYears(Array.from(uniqueYears));
    setServiceTypes(Array.from(uniqueServiceTypes));
  };
  useEffect(() => {
    extractUniqueYearsAndServiceTypes(); // Call this function whenever bookingDetails change
  }, [bookingDetails]);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    console.log("Search Query Updated: ", event.target.value);
  };
  console.log(bookingDetails, "bookingDetails");
  // const filteredBookingDetails = Array.isArray(bookingDetails)
  //   ? bookingDetails.filter((booking) => {
  //       const {
  //         Name,
  //         Email,
  //         mobileOne,
  //         mobileTwo,
  //         mobileThree,
  //         eventDate,
  //         serviceType,
  //         Amount,
  //       } = booking;

  //       let year, month, day;
  //       if (eventDate && typeof eventDate === "string") {
  //         [year, month, day] = eventDate.split("-");
  //       }
  //       let monthName = ""; // Default to empty string
  //       if (month && !isNaN(month)) {
  //         const monthIndex = parseInt(month, 10) - 1;
  //         if (monthIndex >= 0 && monthIndex < monthNames.length) {
  //           monthName = monthNames[monthIndex].toLowerCase();
  //         }
  //       }
  //       const query = searchQuery.toLowerCase(); // Convert query to lowercase
  //       console.log(`Query: ${query}, Month Name: ${monthName}`); // Debug log
  //       const matchesYear = selectedYear ? year === selectedYear : true;
  //       const matchesServiceType = selectedServiceType
  //         ? serviceType === selectedServiceType
  //         : true;

  //       return (
  //         Name.toLowerCase().includes(query) ||
  //         Email.toLowerCase().includes(query) ||
  //         (mobileOne && mobileOne.includes(query)) ||
  //         (mobileTwo && mobileTwo.includes(query)) ||
  //         (mobileThree && mobileThree.includes(query)) ||
  //         monthName.includes(query) || // Month name search
  //         day?.includes(query) ||
  //         serviceType.toLowerCase().includes(query) ||
  //         (Amount.toString().includes(query) && // Convert Amount to string for comparison
  //           matchesYear &&
  //           matchesServiceType)
  //       );
  //     })
  //   : // .sort((a, b) => {
  //     //   const dateA = new Date(a.eventDate);
  //     //   const dateB = new Date(b.eventDate);

  //     //   // Ensure the dates are valid
  //     //   if (isNaN(dateA) || isNaN(dateB)) {
  //     //     return 0; // Maintain original order if dates are invalid
  //     //   }
  //     //   return dateB - dateA; // Sort by eventDate descending
  //     // })
  //     [];

  useEffect(() => {
    // Filtering logic based on the search query, selected year, and selected service type
    const filtered = bookingDetails.filter((booking) => {
      const {
        Name,
        Email,
        mobileOne,
        mobileTwo,
        mobileThree,
        eventDate,
        serviceType,
        Amount,
      } = booking;

      let year, month, day;
      if (eventDate && typeof eventDate === "string") {
        [year, month, day] = eventDate.split("-");
      }

      const query = searchQuery.toLowerCase();
      const matchesYear = selectedYear ? year === selectedYear : true;
      const matchesServiceType = selectedServiceType
        ? serviceType === selectedServiceType
        : true;

      return (
        (Name.toLowerCase().includes(query) ||
          Email.toLowerCase().includes(query) ||
          (mobileOne && mobileOne.includes(query)) ||
          (mobileTwo && mobileTwo.includes(query)) ||
          (mobileThree && mobileThree.includes(query)) ||
          (month && month.toLowerCase().includes(query)) ||
          (day && day.includes(query)) ||
          serviceType.toLowerCase().includes(query) ||
          Amount.toString().includes(query)) &&
        matchesYear &&
        matchesServiceType
      );
    });

    setFilteredBookingDetails(filtered);
  }, [searchQuery, selectedYear, selectedServiceType, bookingDetails]); // Dependencies for filtering

  useEffect(() => {
    fetchDetailsGurdwara();
    fetchBookingDetails();
    if (isAdmin) {
      setActiveTab("BookingList");
    }
  }, [id]);
  let bags =
    "https://e7.pngegg.com/pngimages/686/749/png-clipart-golden-temple-amritsar-car-hire-self-driven-car-rental-in-amritsar-self-drive-car-in-amritsar-india-gurdwara-gurdwara-building-medieval-architecture-thumbnail.png";
  const navigate = useNavigate();
  console.log(years, "years");
  console.log(filteredBookingDetails, "filteredBookingDetails");
  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={4} lg={3}>
            <Sidebar />
          </Col>
          <Col sm={8} lg={9} className="gurdwara-container position-relative ">
            <div
              className="col-lg-6 d-flex row my-3 wc_ib-tabs w-100"
              style={{ marginLeft: "0px" }}
            >
              <div
                className="d-flex mb-3 gap-10 justify-content-end"
                style={{ height: "40px" }}
              >
                {(activeTab === "BookingList" || isAdmin) && (
                  <InputGroup className="justify-content-end">
                    <div style={{ width: "65%" }}>
                      <Input
                        type="search"
                        name="search"
                        id="search"
                        placeholder="Search by name, phonenumber, email, event date, event month, event type..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        style={{ height: "100%" }}
                      />
                    </div>
                    {/* <InputGroupText>
                      <div>
                        <Input
                          type="select"
                          name="year"
                          id="year"
                          placeholder="Select Year"
                          value={selectedYear}
                          onChange={handleYearChange}
                        >
                          <option value="">Year</option>
                          {years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </Input>
                      </div>
                      <div>
                        <div>
                          <Input
                            type="select"
                            name="serviceType"
                            id="serviceType"
                            placeholder="Select Service Type"
                            value={selectedServiceType}
                            onChange={handleServiceTypeChange}
                          >
                            <option value="">Service Type</option>
                            {serviceTypes.map((serviceType) => (
                              <option key={serviceType} value={serviceType}>
                                {serviceType}
                              </option>
                            ))}
                          </Input>
                        </div>
                      </div>
                    </InputGroupText> */}
                    <InputGroupText>
                      <BiSearch style={{ cursor: "pointer" }} />
                    </InputGroupText>
                  </InputGroup>
                )}
              </div>
              {!isAdmin ? (
                <>
                  <h5
                    className={`col-sm-4 ${
                      activeTab === "ManageGurdwara" ? "active " : ""
                    } mx-0`}
                    onClick={() => {
                      setActiveTab("ManageGurdwara");
                    }}
                    style={{
                      textDecoration: activeTab !== "" && "none", // Apply underline when active
                      cursor: "pointer", // Add cursor style to indicate clickable
                      width: "50%",
                    }}
                  >
                    Manage Gurdwara
                  </h5>

                  <h5
                    className={`col-sm-4 ${
                      activeTab === "BookingList" ? "active" : ""
                    } mx-0`}
                    onClick={() => {
                      setActiveTab("BookingList");
                      fetchBookingDetails();
                    }}
                    style={{
                      textDecoration: activeTab !== "ManageGurdwara" && "none", // Apply underline when active
                      cursor: "pointer", // Add cursor style to indicate clickable
                      width: "50%",
                    }}
                  >
                    Booking List
                  </h5>
                </>
              ) : (
                <h6
                  className="fw-bold fs-3 inter-font text-decoration-underline"
                  // className={`col-sm-4 ${
                  //   activeTab === "ManageGurdwara" ? "active " : ""
                  // } mx-0`}
                  // onClick={() => {
                  //   setActiveTab("ManageGurdwara");
                  // }}
                  // style={{
                  //   textDecoration: activeTab !== "" && "none", // Apply underline when active
                  //   cursor: "pointer", // Add cursor style to indicate clickable
                  //   width: "50%",
                  // }}
                >
                  Booking List
                </h6>
              )}
            </div>
            <div className="tab-content">
              {activeTab === "ManageGurdwara" && (
                <div>
                  <ManageGurdwara isLoading={isLoading} gurdwara={gurdwara} />
                </div>
              )}
              {activeTab === "BookingList" && (
                <div>
                  <BookingList
                    isLoading={isLoading}
                    bookingDetails={filteredBookingDetails}
                    fetchBookingDetails={fetchBookingDetails}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default MyGurudwaraPage;
