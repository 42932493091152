// get Order Detail By Id

import { toast } from "react-toastify";
import axiosClient from "../../axios-client";

export const GetItemOrderById = async (id) => {
  try {
    const response = await axiosClient.get(`item/OrderDetailById/${id}`);
    if (response.data.statusCode === 200) {
      return response.data.itemList;
    } else {
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw new Error("Error fetching questions");
  }
};
// get connections By Id

export const GetConnections = async (id) => {
  console.log(id, "FetchConnection");
  try {
    const response = await axiosClient.put(`message/FetchConnection/${id}`);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      // throw new Error(response.data.message);
      toast.info(response.data.message);
    }
  } catch (error) {
    // throw new Error("Error fetching questions");
    toast.error("Error fetching connections");
  }
};
export const GetJobOffers = async (id) => {
  try {
    const response = await axiosClient.put(`message/ListJobOp/${id}`);
    if (response.data.statusCode === 200) {
      return response.data.Data;
    } else {
      // throw new Error(response.data.message);
      toast.info(response.data.message);
    }
  } catch (error) {
    // throw new Error("Error fetching questions");
    toast.error("Error fetching connections");
  }
};
