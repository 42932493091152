import React, { useEffect, useState } from "react";
import { detailsGurudwara } from "../../../components/Header/Data";
import { Button, Col, Row } from "reactstrap";
import { MdEdit } from "react-icons/md";
import Placeholder from "../../../assets/images/Placeholder.png";
import { useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";

const ManageGurdwara = ({ isLoading, gurdwara }) => {
  const navigate = useNavigate();
  console.log(gurdwara, "gurdwara");
  const handleEdit = (service) => {
    navigate(`/manageGurdwara`, { state: service });
  };
  function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    const [hour24, minute] = time24.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0"); // Added padStart
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }
  return (
    <div>
      {isLoading ? (
        <FadeLoader // FadeLoader component
          css={{ margin: "0 auto" }}
          color={"#36D7B7"}
          loading={isLoading}
          className="position-absolute top-50 start-50 translate-middle"
        />
      ) : (
        <div>
          {gurdwara.length > 0 ? (
            <div
              className="service-container mt-4 pb-3 inter-font"
              style={{ marginLeft: "0" }}
            >
              {gurdwara.map((service) => (
                <div
                  className="service rounded px-0 mb-0 mt-3 inter-font"
                  style={{ width: "97%" }}
                >
                  <div key={service.id}>
                    <Row>
                      <Col sm={3} xs={12} className="p-0 position-relative">
                        <img
                          className="mw-100 object-fit-cover border rounded mh-100 img-fluid img-thumbnail"
                          src={
                            Array.isArray(JSON.parse(service?.Photo)) &&
                            JSON.parse(service?.Photo).length > 0
                              ? JSON.parse(service?.Photo)[0]
                              : Placeholder
                          }
                          alt={service.Title}
                          style={{
                            width: "400px",
                            height: "150px",
                            marginLeft: "12px",
                          }}
                        />
                      </Col>
                      <Col sm={9} xs={12} className="p-3 position-relative">
                        <Row className="top-0 end-0 pe-1 mb-2 mx-2">
                          <Col sm={7} xs={12}>
                            <p className=" fs-5 m-0 inter-font fw-bolder text-dark">
                              {service.Title}
                            </p>
                          </Col>
                          <Col sm={5} xs={12} className="text-end">
                            <button
                              className={`p-1 px-2 mx-1 rounded ${
                                service.gurudwaraStatus === 0
                                  ? "bg-warning"
                                  : "bg-success"
                              }`}
                              style={{ marginTop: "4px" }}
                            >
                              <span style={{ color: "black" }}>
                                {service.gurudwaraStatus === 0
                                  ? "Inactive"
                                  : "Active"}
                              </span>
                            </button>
                            <button
                              className="p-1 px-2 mx-1 bg-primary text-white rounded "
                              style={{ marginTop: "4px" }}
                              onClick={() => handleEdit(service)}
                            >
                              Edit <MdEdit />
                            </button>
                          </Col>
                        </Row>
                        <Row className="top-0 end-0 pe-1 mt-1 mx-2">
                          <Col className="my-2">
                            <span className="text-decoration-none text-secondary">
                              {service.Description}
                            </span>
                          </Col>
                        </Row>
                        {service?.serviceInfo &&
                          JSON.parse(service?.serviceInfo).map((info, i) => (
                            <Row className="top-0 end-0 pe-1 mx-2" key={i}>
                              <Col sm="4">
                                <span className="text-decoration-none m-0 text-secondary">
                                  ServiceType: {info.servicetype}
                                </span>
                              </Col>
                              <Col sm="8">
                                {service?.serviceInfo && (
                                  <span className="fs-6 m-0 mx-1 text-secondary">
                                    Availability
                                  </span>
                                )}
                                {info.timeSlots.map((slot, slotIndex) => (
                                  <span
                                    className="m-0 mx-1 text-secondary"
                                    key={slotIndex}
                                  >
                                    From: {convertTo12HourFormat(slot.from)} To:{" "}
                                    {convertTo12HourFormat(slot.to)}{" "}
                                    {slotIndex < info.timeSlots.length - 1
                                      ? " | "
                                      : ""}
                                  </span>
                                ))}
                              </Col>
                              {/* <Col
                                sm={5}
                                xs={12}
                                className="postion-realtive text-center cursor-pointer"
                              >
                                {service?.serviceInfo && i === 0 && (
                                  <p className="fs-6 m-0">Availability</p>
                                )}
                                {info.timeSlots.map((slot, slotIndex) => (
                                  <p className="m-0" key={slotIndex}>
                                    From: {convertTo12HourFormat(slot.from)} To:{" "}
                                    {convertTo12HourFormat(slot.to)}{" "}
                                    {slotIndex < info.timeSlots.length - 1
                                      ? " | "
                                      : ""}
                                  </p>
                                ))}
                              </Col> */}
                            </Row>
                          ))}
                      </Col>
                    </Row>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            isLoading && <div className="ads">No Gurdwaras Added</div>
          )}
        </div>
      )}
    </div>
  );
};

export default ManageGurdwara;
