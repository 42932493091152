import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./slices/cartSlice";
import userSlice from "./slices/userSlice";
import settingSlice from "./slices/settingSlice";
import bwvSlice, { getBwvdata } from "./slices/bwvSlice";
import GiftSlice from "./slices/GiftSlice";
import FreeServiceSlice from "./slices/FreeServiceSlice";
import RequestFormSlice from "./slices/RequestFormSlice";
import ServiceInfoSlice from "./slices/ServiceInfo";
import PaymentSlice from "./slices/PaymentSlice";
import BwvDataSlice from "./slices/BwvData";
import localCommunityAdsSlice from "./slices/LcaSlice";
import MyNeedsSlice from "./slices/MyNeedsSlice";

import MessagesSlice from "./slices/MsgSlice";

import IteamFormSlice from "./slices/IteamFormSlice";
import peopleInCom from "./slices/PeopleInComSlice";
import gurdwaraSlice from "./slices/GurdwaraSlice";

const store = configureStore({
  reducer: {
    cart: cartSlice,
    user: userSlice,
    setting: settingSlice,
    bwv: bwvSlice,
    gift: GiftSlice,
    freeService: FreeServiceSlice,
    requestForm: RequestFormSlice,
    serviceInfo: ServiceInfoSlice,
    payment: PaymentSlice,
    bwvData: BwvDataSlice,
    lcAds: localCommunityAdsSlice,
    myNeeds: MyNeedsSlice,
    messages: MessagesSlice,
    IteamForm: IteamFormSlice,
    peopleInCom: peopleInCom,
    gurdwara: gurdwaraSlice,
  },
});

store.dispatch(getBwvdata());

export default store;
