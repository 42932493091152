/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import "./RecentAds.css";
import { fetchLCAById, fetchLocalCommunityAds } from "../Data";
import PlaceHolder from "../../../assets/images/Placeholder.png";
import Recommended from "./Recommended";
import { FadeLoader } from "react-spinners";
import { FaArrowLeft } from "react-icons/fa";
import LcaSlideImages from "./LcaSlideImages";
import LcaInfo from "./LcaInfo";
const CardDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const { Id: cardId } = useParams();
  const [recomended, setRecomended] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCard, setSelectedCard] = useState(null);
  const [iygt, setIygt] = useState(null);
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const ads = await fetchLocalCommunityAds();
      const request = await fetchLCAById(cardId);
      setIsLoading(false);
      let lcaAd = request[0];
      console.log(lcaAd, "lcaAd");
      const filterAds = ads.filter(
        (ad) =>
          ad.categoryId === lcaAd.categoryId &&
          ad.subCategoryId === lcaAd.subCategoryId &&
          ad.Zipcode === lcaAd.Zipcode &&
          ad.Id !== lcaAd.Id
      );
      setRecomended(filterAds);
      setSelectedCard(lcaAd); // Access the first element of the array
    } catch (error) {
      console.error("Error fetching allProducts:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, [cardId]);

  return (
    <div className="bg-white">
      <p
        onClick={() => {
          navigate(-1);
        }}
        style={{
          cursor: "pointer",
          // position: "absolute",
          top: "19px",
          left: "10%",
          fontSize: "18px",
          color: "#000",
          zIndex: 999,
        }}
        className="m-2 d-xl-none"
      >
        <FaArrowLeft
          style={{
            marginRight: "6px",
            fontSize: "18px",
            marginTop: "-3px",
          }}
        />
        Back
      </p>
      <div className="container py-5">
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "150px",
            }}
          >
            <FadeLoader color="#36d7b7" />
          </div>
        ) : (
          <>
            <Row>
              <Col sm={5} xs="12">
                <LcaSlideImages selectedCard={selectedCard} />
              </Col>
              <Col sm={7} xs="12" className="card-details pt-0">
                <LcaInfo selectedCard={selectedCard} isLoading={isLoading} />
              </Col>
            </Row>
            <div>
              <h5>Similar Ads</h5>
              {!isLoading ? (
                <Recommended recomended={recomended} />
              ) : (
                <p
                  className={`${
                    isLoading ? "m-0 p-0" : ""
                  } text-center fw-bold`}
                >
                  Loading...
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CardDetails;
