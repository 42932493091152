import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import MakeOfferModal from "../modals/MakeOfferModal";
import ShareModal from "./ShareModal";
import MsgModal from "../modals/MsgModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import Maps from "../../../pages/DashboardPages/Maps";
import moment from "moment-timezone";
import "moment-timezone/builds/moment-timezone-with-data";

const LcaInfo = ({ selectedCard, isLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [isMessage, setIsMessage] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const [isMsgModalOpen, setIsMsgModalOpen] = useState(false);
  const [schedule, setSchedule] = useState(false);
  const [city, setCity] = useState("");
  const openMsgModal = () => {
    if (!islogin) {
      toast.info("Please Login");
      navigate("/login");
    } else {
      setIsMessage(true);
      setIsMsgModalOpen(true);
    }
  };
  const closeModal = () => {
    setIsMsgModalOpen(false);
    setOpenShareModal(false);
    setIsOfferModalOpen(false);
    setSchedule(false);
  };
  const openOfferModal = (event, ad) => {
    if (!islogin) {
      toast.info("Please Login");
      navigate("/login");
    } else {
      setIsMessage(false);
      setIsOfferModalOpen(true);
    }
  };
  let placeHolder =
    "https://st4.depositphotos.com/6672868/20448/v/600/depositphotos_204485038-stock-illustration-user-vector-icon-simple-illustration.jpg";

  function convertTo12HourFormat(time24) {
    if (!time24) {
      return "";
    }
    const [hour24, minute] = time24.split(":").map(Number);
    if (isNaN(hour24) || isNaN(minute)) {
      return "";
    }
    const period = hour24 >= 12 ? "PM" : "AM";
    const hour12 = (hour24 % 12 || 12).toString().padStart(2, "0"); // Added padStart
    return `${hour12}:${minute.toString().padStart(2, "0")} ${period}`;
  }

  function formatDate(inputDate) {
    let date = new Date(inputDate);
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let year = date.getFullYear();
    let month = monthNames[date.getMonth()];
    let day = date.getDate();
    let formattedDay = day < 10 ? "0" + day : day;
    let formattedDate = `${month} ${formattedDay}, ${year}`;
    return formattedDate;
  }

  function convertToUserTimezone(utcTime) {
    let timezone = "America/Los_Angeles"; // Default to system timezone
    if (islogin && user?.TimeZone) {
      timezone = user?.TimeZone.split(",")[0];
    }
    const localTime = moment
      .utc(utcTime)
      .tz(timezone)
      .format("YYYY-MM-DD HH:mm:ss");
    return localTime;
  }

  return (
    <div>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        className="pb-3"
      >
        <Col sm="6" xs="12" className="d-flex align-items-center p-2">
          <img
            src={
              selectedCard?.profilePhoto
                ? selectedCard.profilePhoto
                : placeHolder
            }
            style={{
              width: 50,
              height: 50,
              objectFit: "contain",
            }}
            className="rounded-circle me-3"
            alt=""
          />
          <h6>
            {selectedCard?.FullName ? selectedCard?.FullName : "UserName"}
          </h6>
        </Col>
        <Col sm="6" xs="12" className="d-lg-flex align-items-center gap-10 p-2">
          {selectedCard?.engagedCouple === 1 && (
            <>
              <a
                className="btn btn-primary m-0 btn-sm"
                onClick={openOfferModal}
              >
                Make An Offer
              </a>
              <MakeOfferModal
                isOpen={isOfferModalOpen}
                closeModal={closeModal}
                selectedCard={selectedCard}
                setSchedule={setSchedule}
                schedule={schedule}
              />
            </>
          )}
          {selectedCard?.engagedCouple === 0 && (
            <>
              <a href={`tel:8002453610`} className={``}>
                <button className={`btn btn-success btn-sm m-1`}>
                  {/* <FaPhoneSquare /> */}
                  &nbsp; Call: (800) 245-3610 <br />
                  <span>Extension # {selectedCard.dialExtension}</span>
                </button>
              </a>
              <a
                className="btn btn-primary m-0 btn-sm mx-1"
                onClick={openMsgModal}
              >
                Send Message
              </a>
              <a>
                <button
                  className="btn btn-success m-0 btn-sm"
                  onClick={() => setOpenShareModal(true)}
                >
                  Share
                </button>
              </a>
              <ShareModal
                isOpen={openShareModal}
                closeModal={closeModal}
                selectedCard={selectedCard}
              />
            </>
          )}
        </Col>
      </Row>
      <MsgModal
        isOpen={isMsgModalOpen}
        closeModal={closeModal}
        selectedCard={selectedCard}
        isMessage={isMessage}
      />
      <div className="row">
        <div className="col-lg-8">
          <p>Request &gt;&gt; {selectedCard.categoryName}</p>
        </div>
      </div>
      <Row className="card-details-dec">
        <Col sm="6" xs="12">
          <h4>{selectedCard.Title}</h4>
          <div style={{ fontSize: "small", paddingTop: "20px" }}>
            {selectedCard.Description}
          </div>
          <div
            className="product-cart d-flex align-items-center justify-content-between my-3"
            style={{ fontSize: "13px" }}
          >
            {selectedCard?.Zipcode && (
              <div
                style={{ display: "flex", flexDirection: "column", gap: 10 }}
              >
                <h6 style={{ opacity: 0.7 }}>Location : </h6>
                <span>{city}</span>
              </div>
            )}
          </div>
          <div>
            <p className="mb-0 text-dark" style={{ fontSize: "small" }}>
              Posting Date/Time:{" "}
              {/* {formatDate(selectedCard?.createdAt.split("T")[0])} &nbsp;
              {convertTo12HourFormat(
                selectedCard?.createdAt.split("T")[1].split("T")[0]
              )}
              &nbsp; &nbsp; */}
              {formatDate(
                convertToUserTimezone(selectedCard?.createdAt).split(" ")[0]
              )}
              &nbsp;
              {convertTo12HourFormat(
                convertToUserTimezone(selectedCard?.createdAt).split(" ")[1]
              )}
            </p>
          </div>
        </Col>
        <Col sm="6" xs="12">
          <Maps zipCode={selectedCard?.Zipcode} setCity={setCity} />
        </Col>
      </Row>
      <div className="d-flex justify-content-end m-3">
        <Link to="/Localcommunityads" style={{ color: "blue" }}>
          See All
        </Link>
      </div>
    </div>
  );
};

export default LcaInfo;
