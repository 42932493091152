import React, { useState, useEffect } from "react";
import Sidebar from "../../SideBar";
import { Row, Col } from "reactstrap";
import "../DashboardPages.css";
import { getAllOrders } from "../../../components/Header/Data";
import { useSelector } from "react-redux";
import AsProvider from "./AsProvider";
import AsClint from "./AsClint";
import ItemsInCart from "./ItemsInCart";
import { useHistory, useLocation, useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import Cart from "../../Cart";
const Orders = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get("tab") || "as-provider";
  const [selectedType, setSelectedType] = useState(initialTab);
  const [adminOrders, setAdminOrders] = useState([]);
  const [providerOrders, setProviderOrders] = useState([]);
  const [clintOrders, setClintOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const fetchData = async () => {
    setLoading(true);
    try {
      if (user.RoleId === 1) {
        const formId = {
          roleId: user.RoleId,
        };
        const ordersData = await getAllOrders(formId);
        setAdminOrders(ordersData);
        handleTabChange("all-orders");
      } else {
        const formIdProvider = {
          ownerId: user.UserID,
        };
        const providersData = await getAllOrders(formIdProvider);
        setProviderOrders(providersData);
        const formId = {
          createdBy: user.UserID,
        };
        const clintsData = await getAllOrders(formId);
        setClintOrders(clintsData);
      }
    } catch (error) {
      console.error("Error fetching orders:", error.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleTabChange = (tab) => {
    setSelectedType(tab);
    navigate(`?tab=${tab}`);
  };
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "50px 0px",
          height: "80vh",
        }}
      >
        <FadeLoader color="#36d7b7" />
      </div>
    );
  }

  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={4} lg={3}>
            <Sidebar />
          </Col>
          {isAdmin ? (
            <Col sm={8} lg={9}>
              <Row className="orders-container inter-font">
                <div
                  className="d-flex justify-space-between row m-2 mt-3 wc_ib-tabs"
                  style={{ marginLeft: "2px" }}
                >
                  <h5
                    className={`col-sm-4 ${
                      selectedType === "all-orders" ? "active" : ""
                    } mx-0 inter-font`}
                    onClick={() => handleTabChange("all-orders")}
                    style={{
                      textDecoration: selectedType === "all-orders " && "none",
                      cursor: "pointer",
                      width: "50%",
                      fontSize: "14px",
                    }}
                  >
                    All Orders
                  </h5>
                  <h5
                    className={`col-sm-4 ${
                      selectedType === "items-in-cart" ? "active" : ""
                    } mx-0 inter-font`}
                    onClick={() => handleTabChange("items-in-cart")}
                    style={{
                      textDecoration:
                        selectedType === "items-in-cart" && "none",
                      cursor: "pointer",
                      width: "50%",
                      fontSize: "14px",
                    }}
                  >
                    Items In Cart
                  </h5>
                </div>
                {selectedType === "all-orders" && (
                  <AsProvider orders={adminOrders} />
                )}
                {selectedType === "items-in-cart" && <ItemsInCart />}
              </Row>
            </Col>
          ) : (
            <Col sm={8} lg={9}>
              <Row className="orders-container inter-font mt-5">
                <div
                  className="d-flex justify-space-between row m-2 mt-3 wc_ib-tabs"
                  style={{ marginLeft: "2px" }}
                >
                  <h5
                    className={`col-sm-4 ${
                      selectedType === "as-provider" ? "active" : ""
                    } mx-0 inter-font`}
                    onClick={() => handleTabChange("as-provider")}
                    style={{
                      textDecoration: selectedType === "as-provider " && "none",
                      cursor: "pointer",
                      width: "33%",
                      fontSize: "14px",
                    }}
                  >
                    As Provider
                  </h5>
                  <h5
                    className={`col-sm-4 ${
                      selectedType === "as-client" ? "active" : ""
                    } mx-0 inter-font`}
                    onClick={() => handleTabChange("as-client")}
                    style={{
                      textDecoration: selectedType === "as-client" && "none",
                      cursor: "pointer",
                      width: "33%",
                      fontSize: "14px",
                    }}
                  >
                    As Client
                  </h5>
                  <h5
                    className={`col-sm-4 ${
                      selectedType === "items-in-cart" ? "active" : ""
                    } mx-0 inter-font`}
                    onClick={() => handleTabChange("items-in-cart")}
                    style={{
                      textDecoration:
                        selectedType === "items-in-cart" && "none",
                      cursor: "pointer",
                      width: "33%",
                      fontSize: "14px",
                    }}
                  >
                    Items In Cart
                  </h5>
                </div>
                {/* <Row className="mt-3"> */}
                {selectedType === "as-provider" && (
                  <AsProvider orders={providerOrders} />
                )}
                {selectedType === "as-client" && (
                  <AsClint orders={clintOrders} />
                )}
                {/* {selectedType === "items-in-cart" && <Cart />} */}
                {selectedType === "items-in-cart" && <ItemsInCart />}
                {/* </Row> */}
              </Row>
            </Col>
          )}
        </Row>
      </div>
    </section>
  );
};

export default Orders;
