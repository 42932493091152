import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { CiMenuKebab } from "react-icons/ci";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { FadeLoader } from "react-spinners";
import UpdateUserModal from "./UpdateUserModal";
import { useSelector } from "react-redux";
import { DeleteUser } from "../../components/Header/Data2";
import { toast } from "react-toastify";
import { RibbonContainer, Ribbon } from "react-ribbons";
import GitHubForkRibbon from "react-github-fork-ribbon";
import PlaceHolder from "../../assets/images/Placeholder.png";

const UserCard = ({ allUsers, fetchUsers }) => {
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [openMenuId, setOpenMenuId] = useState(null);
  const usersPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [openUpdateUserModal, setOpenUpdateUserModal] = useState(false);
  const [userToUpdate, setUserToUpdate] = useState(null);

  const totalPages = Math.ceil(allUsers.length / usersPerPage);

  if (!allUsers || allUsers.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "200px",
        }}
      >
        <FadeLoader color="#36d7b7" />
      </div>
    );
  }

  const toggleMenu = (adId) => {
    setOpenMenuId(openMenuId === adId ? null : adId);
  };

  const onDelete = async (deleteUser) => {
    const reqData = {
      DeletedBy: user.UserID,
    };
    await DeleteUser(deleteUser.Id, reqData);
    toast.info(`User Deleted`);
    setOpenMenuId(null);
    fetchUsers();
  };

  const onEdit = (user) => {
    console.log("Edit ad:", user);
    setUserToUpdate(user);
    setOpenUpdateUserModal(true);
  };
  // Function to capitalize only the first letter of a string
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + " ";
    }
    return text;
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  // Calculate the index of the first and last user to be displayed on the current page
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = allUsers.slice(indexOfFirstUser, indexOfLastUser);

  console.log(allUsers, "allUsers");

  return (
    <div>
      <Row className="user-content">
        {currentUsers.map((user) => (
          <Col key={user.Id} sm={6} md={6} lg={4}>
            <Card className="mb-3 postion-relative">
              {user?.caIdStatus === 1 ? (
                <GitHubForkRibbon color="green" position="left">
                  Verified & Trusted
                </GitHubForkRibbon>
              ) : null}
              <CardBody>
                <div key={user.Id} className="">
                  <Dropdown
                    isOpen={openMenuId === user.Id}
                    toggle={() => toggleMenu(user.Id)}
                  >
                    <DropdownToggle className="p-0">
                      <CiMenuKebab
                        size={17}
                        className="position-absolute top-0 end-0 fw-bolder"
                        color="black"
                      />
                    </DropdownToggle>
                    <span
                      style={{
                        display: openMenuId === user.Id ? "block" : "none",
                        backgroundColor: "#F2F3F4",
                        padding: "3px 5px 11px 5px",
                        transform: "translateY(10px)",
                        right: "-15px",
                        top: "10px",
                      }}
                      className="h-auto w-50 rounded position-absolute"
                    >
                      <DropdownItem
                        onClick={() => onEdit(user)}
                        className="bg-primary text-white rounded-pill "
                      >
                        Edit <AiFillEdit size={15} />
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => onDelete(user)}
                        className="bg-danger text-white rounded-pill "
                      >
                        Delete <AiFillDelete size={15} />
                      </DropdownItem>
                    </span>
                  </Dropdown>
                </div>
                <div className="text-center mb-3 ">
                  <img
                    src={
                      user.Photo !== "" && user.Photo !== null
                        ? user?.Photo
                        : PlaceHolder
                    }
                    alt={user.email}
                    className="user-avatar rounded-circle"
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center mb-3">
                  <h2 className="text-center mb-0 lead fs-3 fw-bold">
                    {/* {capitalizeFirstLetter(truncateText(user.UserName, 9))} */}
                    {user?.FirstName}&nbsp; {user?.LastName}
                  </h2>
                </div>
                <div className="user-details lead fw-bolder">
                  <p>Email: {user.Email}</p>
                  <p>Bio: {user.BioData}</p>
                  <p>Mobile No: {user.MobileNo}</p>
                  <p>Role: {user.Role}</p>
                  <p>ID: {user.Id}</p>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      <UpdateUserModal
        isOpen={openUpdateUserModal}
        closeModal={() => setOpenUpdateUserModal(false)}
        userInfo={userToUpdate}
      />
      {/* Pagination controls */}
      <div className="pagination pb-5 d-flex justify-content-center">
        <Button
          className="me-2"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span className="align-self-center">
          Page {currentPage} of {totalPages}
        </span>
        <Button
          className="ms-2"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default UserCard;
