import React, { useEffect, useState } from "react";
import Sidebar from "../../SideBar";
import {
  Row,
  Col,
  Button,
  Input,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { BiPlusCircle, BiSearch } from "react-icons/bi";
import "../DashboardPages.css";
import "./Styles/MyServices.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMyServices,
  fetchAllServices,
} from "../../../components/Header/Data";
import PendingServices from "./PendingService";
import ActiveServices from "./ActiveServices";
import { FadeLoader } from "react-spinners"; // Import FadeLoader
import ServiceDraft from "./ServiceDraft";
import BizzList from "./BizzListings";
import { FaSearch } from "react-icons/fa";
import { setAllServices, setMyServices } from "../../../redux/slices/BwvData";
const MyServicesPage = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const userinfo = useSelector((state) => state.user.userInfo);
  const { allServices, myServices } = useSelector((state) => state.bwvData);
  const islogin = useSelector((state) => state.user.isLogin);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [activeTab, setActiveTab] = useState("Approved");
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  let activeServices = [];
  let bizzServices = [];
  let pausedServices = [];
  let drafts = [];
  let pending = [];
  /*status
  drafts -- 0
  pending -- 1
  deleted -- 2
  Approved (active) -- 3
  Approved (paused) -- 4 */
  drafts = myServices.filter((ad) => ad.isactive === "0");
  pending = myServices.filter((ad) => ad.isactive === "1");
  activeServices = myServices.filter((ad) => ad.isactive === "3");
  bizzServices = myServices.filter(
    (ad) => ad?.bizzValue === 1 || ad?.bizzValue === 2 || ad?.bizzValue === 3
  );
  pausedServices = myServices.filter((ad) => ad.isactive === "4");
  const fetchData = async () => {
    setIsLoading(true);
    if (user.RoleId === 1) {
      const services = await fetchAllServices();
      dispatch(setAllServices(services));
      dispatch(setMyServices(services));
    } else if (user.RoleId !== 1) {
      const reqData = {
        roleId: 2,
        createdBy: user.UserID,
      };
      const services = await fetchMyServices(reqData);
      dispatch(setAllServices(services));
      dispatch(setMyServices(services));
      const filterActive = services.filter(
        (service) => !["0", "1"].includes(service.isactive)
      );
      if (filterActive.length > 0) {
        localStorage.setItem("USER_ROLE", "provider");
      } else {
        localStorage.setItem("USER_ROLE", "customer");
      }
      const logUser = localStorage.getItem("USER_ROLE");
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  const handleSearchService = () => {
    if (search?.trim() !== "") {
      const trimmedQuery = search.trim().toLowerCase();
      const filterService = allServices.filter(
        (ad) =>
          ad.listName.toLowerCase().includes(trimmedQuery) ||
          ad.Email?.toLowerCase().includes(trimmedQuery) ||
          ad.Phone?.toLowerCase().includes(trimmedQuery) ||
          ad.FullName?.toLowerCase().includes(trimmedQuery) ||
          ad.dialExtension?.toLowerCase().includes(trimmedQuery) ||
          ad.categoryName?.toLowerCase().includes(trimmedQuery)
      );
      dispatch(setMyServices(filterService));
    } else {
      fetchData();
    }
  };
  useEffect(() => {
    handleSearchService();
  }, [search]);
  useEffect(() => {
    setSearch("");
  }, [activeTab]);
  useEffect(() => {
    fetchData();
  }, []);
  let bags = "https://myindiancommunity.org/adminv2/img/icons/any-listing.png";
  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={4} lg={3}>
            <Sidebar />
          </Col>
          <Col sm={8} lg={9} className="requests-container position-relative ">
            {isLoading && (
              <FadeLoader // FadeLoader component
                css={{ margin: "0 auto" }}
                color={"#36D7B7"}
                loading={isLoading}
                className="position-absolute top-50 start-50 translate-middle"
              />
            )}
            {allServices.length > 0 ? (
              <div>
                <Row className="wc-service-search">
                  <Col md={8} lg={8} xs={8}>
                    {isAdmin && (
                      <InputGroup>
                        <Input
                          type="search"
                          placeholder="Search by Listing Name..."
                          onChange={(e) => setSearch(e.target.value)}
                          value={search}
                        />
                        <InputGroupText>
                          <BiSearch />
                        </InputGroupText>
                      </InputGroup>
                    )}
                  </Col>
                  <Col
                    className="d-flex justify-content-end"
                    md={4}
                    lg={4}
                    xs={4}
                  >
                    <Button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        navigate("/create-service");
                      }}
                    >
                      <BiPlusCircle /> Add New
                    </Button>
                  </Col>
                </Row>

                <div
                  className="col-lg-6 d-flex row mt-3 wc_ib-tabs w-100"
                  style={{ marginLeft: "2px" }}
                >
                  <h5
                    className={`col-sm-4 ${
                      activeTab === "Approved" ? "active " : ""
                    } mx-0`}
                    onClick={() => {
                      setActiveTab("Approved");
                      isLoading ? (
                        <FadeLoader // FadeLoader component
                          css={{ margin: "0 auto" }}
                          color={"#36D7B7"}
                          loading={isLoading}
                          className="position-absolute top-50 start-50 translate-middle"
                        />
                      ) : (
                        fetchData()
                      );
                    }}
                    style={{
                      textDecoration: activeTab !== "Approved" && "none", // Apply underline when active
                      cursor: "pointer", // Add cursor style to indicate clickable
                      width: isAdmin ? "25%" : "33%",
                    }}
                  >
                    Approved
                  </h5>
                  <h5
                    className={`col-sm-4 ${
                      activeTab === "pending" ? "active" : ""
                    } mx-0`}
                    onClick={() => setActiveTab("pending")}
                    style={{
                      textDecoration: activeTab !== "Approved" && "none", // Apply underline when active
                      cursor: "pointer", // Add cursor style to indicate clickable
                      width: isAdmin ? "25%" : "33%",
                    }}
                  >
                    Pending
                  </h5>
                  <h5
                    className={`col-sm-4  ${
                      activeTab === "draft" ? "active" : ""
                    } mx-0`}
                    onClick={() => setActiveTab("draft")}
                    style={{
                      textDecoration: activeTab !== "Approved" && "none", // Apply underline when active
                      cursor: "pointer", // Add cursor style to indicate clickable
                      width: isAdmin ? "25%" : "33%",
                    }}
                  >
                    Draft
                  </h5>
                  {isAdmin && (
                    <h5
                      className={`col-sm-4 ${
                        activeTab === "Bizz_Lising" ? "active " : ""
                      } mx-0`}
                      onClick={() => {
                        setActiveTab("Bizz_Lising");
                        fetchData();
                      }}
                      style={{
                        textDecoration: activeTab !== "Bizz_Lising" && "none", // Apply underline when active
                        cursor: "pointer", // Add cursor style to indicate clickable
                        width: "25%",
                      }}
                    >
                      Bizz Lisings
                    </h5>
                  )}
                </div>
                <div className="tab-content" style={{ marginTop: "-7px" }}>
                  {activeTab === "Approved" && (
                    <div>
                      <ActiveServices
                        activeServices={activeServices}
                        user={user}
                        fetchData={fetchData}
                        isLoading={isLoading}
                      />
                    </div>
                  )}
                  {activeTab === "pending" && (
                    <div>
                      <PendingServices
                        pending={pending}
                        user={user}
                        fetchData={fetchData}
                        isLoading={isLoading}
                      />
                    </div>
                  )}
                  {activeTab === "draft" && (
                    <div>
                      <ServiceDraft
                        drafts={drafts}
                        user={user}
                        fetchData={fetchData}
                        isLoading={isLoading}
                      />
                    </div>
                  )}
                  {activeTab === "Bizz_Lising" && (
                    <div>
                      <BizzList
                        activeServices={bizzServices}
                        user={user}
                        fetchData={fetchData}
                        isLoading={isLoading}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              !isLoading && (
                <div className="ads">
                  <img src={bags} alt="bags" style={{ width: "10rem" }} />
                  <h3 className="mb-4">You haven't created any listings yet</h3>
                  <p className="mb-4">
                    Have something to sell? Start making money right away!
                  </p>
                  <Button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      navigate("/create-service");
                    }}
                  >
                    <BiPlusCircle /> Add New
                  </Button>
                </div>
              )
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
};
export default MyServicesPage;
