import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Input, Label, Row } from "reactstrap";
import { detailsGurudwara } from "../../../../components/Header/Data";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setAdditionalPeople,
  setDetailGurdwara,
  setEventDate,
  setEventTime,
  setEventLocation,
  setMobileNumber1,
  setMobileNumber2,
  setMobileNumber3,
  setSelectedServiceType,
  setSelectedServicePrice,
} from "../../../../redux/slices/GurdwaraSlice";

const BookGurdwaraStep2 = () => {
  const dispatch = useDispatch();
  const {
    detailGurdwara,
    eventDate,
    eventTime,
    eventLocation,
    additionalPeople,
    selectedServiceType,
    serviceTypes,
    servicePrices,
  } = useSelector((state) => state.gurdwara);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  console.log(id, "Id");
  // const API_KEY = "GF3H2GZSMNSMS4WBOBYCAIZ25GIVA3YZ";

  const API_KEY = "FFO6YBKXCTITKXORQ2P3M5MJUGQAW4WX";
  const handleServiceChange = (event) => {
    const selectedType = event.target.value;
    console.log(typeof selectedType, "selectedType");
    dispatch(setSelectedServiceType(selectedType));
    console.log(servicePrices, "servicePrices");
    dispatch(setSelectedServicePrice(servicePrices[selectedType] || "")); // Update the price based on the selected service
  };
  const fetchDetailsGurdwara = async () => {
    const response = await detailsGurudwara(id);
    console.log(response.Data[0], "response");
    dispatch(setDetailGurdwara(response.Data[0]));
  };
  useEffect(() => {
    fetchDetailsGurdwara();
  }, []);
  // console.log(JSON.parse(service.serviceInfo), "serviceInfo");

  const formatTime = (day, time) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const today = new Date();
    const dayOfWeek = daysOfWeek.indexOf(day);
    const currentDayOfWeek = today.getDay();
    const diff = (dayOfWeek + 7 - currentDayOfWeek) % 7; // Handle week wrap around
    const eventDate = new Date(today);
    eventDate.setDate(today.getDate() + diff);
    const [hours, minutes] = time.split(":").map(Number);
    eventDate.setHours(hours, minutes, 0, 0);
    return eventDate.toISOString();
  };

  const scheduleEvent = async (service) => {
    const { servicetype, timeSlots } = service;

    for (const slot of timeSlots) {
      const { days, from, to } = slot;

      for (const day of days) {
        const startTime = formatTime(day, from);
        const endTime = formatTime(day, to);

        try {
          const response = await axios.post(
            "https://api.calendly.com/scheduled_events",
            {
              event: {
                start_time: startTime,
                end_time: endTime,
                location: {
                  type: "physical",
                  location: "123 Main St, San Francisco, CA 94105",
                },
                name: servicetype,
                description: `Service: ${servicetype}, Price: ${service.serviceprice}, Count: ${service.serviceCount}`,
              },
              invitees: [
                {
                  email: "invitee@example.com", // Replace with actual invitee email
                },
              ],
            },
            {
              headers: {
                Authorization: `Bearer ${API_KEY}`,
                "Content-Type": "application/json",
              },
            }
          );

          console.log("Event created:", response.data);
        } catch (error) {
          console.error("Error creating event:", error.response.data);
        }
      }
    }
  };
  const handleNext = () => {
    // setCurrentStep((prev) => prev + 1);
    const parsedServiceInfo = JSON.parse(detailGurdwara.serviceInfo);
    parsedServiceInfo.forEach(scheduleEvent);
    console.log("Next");
  };

  return (
    // <div>
    //   <div className="d-flex align-items-center justify-content-center">
    //     <div
    //       className="page1"
    //       style={{
    //         height: currentStep === 3 || currentStep === 4 ? "750px" : "405px",
    //       }}
    //     >
    //       <div className="d-flex justify-content-between mx-5 my-4">
    //         <div className="fw-bold fs-5">
    //           <span
    //             onClick={() => setCurrentStep((prev) => prev - 1)}
    //             className="text-primary"
    //             style={{ cursor: "pointer" }}
    //           >
    //             Section1
    //           </span>
    //           &gt;<span className="text-decoration-underline">Section2</span>
    //         </div>
    //         <button className="btn btn-primary" onClick={handleNext}>
    //           Next
    //         </button>
    //       </div>
    <div className="mx-5 my-4">
      <Row>
        <Col>
          <Label>
            Service Type <b className="text-danger">*</b>
          </Label>
          <Input
            className="mb-3"
            type="select"
            value={selectedServiceType}
            onChange={handleServiceChange}
          >
            <option value="0">Select service type here</option>
            {serviceTypes.map((serviceType, index) => (
              <option key={index} value={serviceType}>
                {serviceType}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Label>
            Event Date <b className="text-danger">*</b>
          </Label>
          <Input
            type="date"
            name="eventDate"
            id="eventDate"
            value={eventDate}
            onChange={(e) => dispatch(setEventDate(e.target.value))}
            min={new Date().toISOString().split("T")[0]}
          />
        </Col>
        <Col sm={4}>
          <Label>
            Event Time <b className="text-danger">*</b>
          </Label>
          <Input
            type="time"
            name="eventTime"
            id="eventTime"
            value={eventTime}
            onChange={(e) => dispatch(setEventTime(e.target.value))}
          />
        </Col>
        <Col sm={4}>
          <Label>
            Event Location <b className="text-danger">*</b>
          </Label>
          <Input
            type="text"
            name="eventLocation"
            id="eventLocation"
            value={eventLocation}
            onChange={(e) => dispatch(setEventLocation(e.target.value))}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Label>Additional Details</Label>
          <Input
            type="text"
            name="additionalPeople"
            id="additionalPeople"
            value={additionalPeople}
            placeholder="Please explain details you would like the Gurdwara to know about your event"
            onChange={(e) => dispatch(setAdditionalPeople(e.target.value))}
          />
        </Col>
      </Row>
    </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default BookGurdwaraStep2;
