import { Alert, Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from "reactstrap";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import axiosClient from "../../axios-client";
import { toast } from "react-toastify";
import { countryCodes } from "../../components/CountryCodes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FaLocationArrow,
  FaMapMarked,
  FaMapMarkedAlt,
  FaMapMarker,
  FaMapMarkerAlt,
  FaMapSigns,
  FaSearchLocation,
} from "react-icons/fa";
import { getMyCartItems } from "../../components/Header/MyCart";
const AddToCartModal = ({ open, toggle, selectedItem, myCartItems }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [typeOfDelivery, setTypeOfDelivery] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = React.useState("");
  const [country, setCountry] = useState("+1");
  const dispatch = useDispatch();
  // Array of delivery types with their values and labels
  const deliveryOptionsMap = {
    1: { label: "Standard 3 Days Delivery", cost: "$5.00" },
    2: { label: "Same Day Delivery", cost: "$10.00" },
    3: { label: "Pick Up In Person", cost: "Free" },
  };
  const countryOptions = countryCodes.map((country) => ({
    label: `${country.name} (${country.dial_code}) `,
    value: country.dial_code,
  }));
  // Parse delivery options from selectedItem
  const Options =
    selectedItem && selectedItem.delivery_option
      ? JSON.parse(selectedItem.delivery_option) // Assuming this is a stringified JSON array
      : [];

  const deliveryOptions = Options.map((value) => ({
    value,
    ...deliveryOptionsMap[value], // Get the corresponding delivery option from the map
  }));
  const handleChange = (event) => {
    setTypeOfDelivery(event.target.value);
  };
  const toggleModal = () => {
    toggle();
    setTypeOfDelivery("");
    setAddress("");
    setMessage("");
    setPhone("");
  };
  const AddtoCart = async () => {
    const request = {
      userId: user.UserID,
      itemId: selectedItem.Id,
      Description: message,
      Quantity: 1,
      Price: selectedItem.price,
      cartStatus: 0,
      deliveryOption: typeOfDelivery,
      Address: address,
      Phone: country + phone,
      createdBy: selectedItem.created_by,
    };

    try {
      const response = await axiosClient.post("item/AddToCart", request);
      if (response.status === 200) {
        toast.success(`${selectedItem.name} added to cart successfully`);
        toggleModal();
        getMyCartItems(user.UserID, dispatch);
      }
    } catch (error) {
      if (error.response && error.response.data.statusCode === 422) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Failed to add item to cart");
      }
    }
    // getAllIfs();
    myCartItems();
  };

  return (
    <div>
      <Modal isOpen={open} toggle={toggleModal}>
        <Form onSubmit={AddtoCart}>
          <ModalHeader toggle={toggleModal}>
            Add {selectedItem?.name} To Cart
          </ModalHeader>
          <ModalBody>
            <Box sx={{ minWidth: 70 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Delivery Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={typeOfDelivery}
                  label="Delivery Type"
                  onChange={handleChange}
                  required={true}
                  fullWidth
                >
                  {/* Dynamically render the delivery options */}
                  {deliveryOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                      <span className="text-primary fw-bold ps-1">
                        {option.cost}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {typeOfDelivery === 1 || typeOfDelivery === 2 ? (
              <>
                {" "}
                <Box mt={2}>
                  <p className="text-danger ">
                    Deliveries can be made only to adresses 7 miles from Zipcode
                  </p>
                </Box>{" "}
                <Box mt={2}>
                  <TextField
                    fullWidth
                    label="Delivery Address"
                    variant="outlined"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required={typeOfDelivery === 1 || 2}
                  />
                </Box>
              </>
            ) : typeOfDelivery === 3 ? (
              <Box mt={2}>
                <p className="text-danger ">
                  Your item will be available at the below address, Please
                  message the seller to ask availability schedule.
                </p>
                <h6>
                  <span style={{ fontFamily: "serif" }}>Pickup Address</span> :{" "}
                  {selectedItem?.address} <FaMapMarkerAlt color="red" />
                </h6>
              </Box>
            ) : null}
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="country-code-label">Country</InputLabel>
                    <Select
                      labelId="country-code-label"
                      id="country-code"
                      label="Country"
                      variant="outlined"
                      required
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      fullWidth
                      sx={{ height: "56px" }} // Adjust height to match TextField
                    >
                      {countryOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value} {/* Only show the country code */}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    id="phone"
                    fullWidth
                    label="Phone"
                    variant="outlined"
                    required
                    type="tel"
                    placeholder="Phone Number"
                    value={phone}
                    inputProps={{
                      maxLength: 10,
                      autocomplete: "tel",
                      minLength: 10,
                    }}
                    sx={{ height: "56px" }}
                    onInput={(e) => {
                      const value = e.target.value;
                      const numericValue = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                      if (numericValue.length <= 10) {
                        setPhone(numericValue); // Update phone state with the numeric value
                        e.target.value = numericValue; // Update input value
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <div className="mt-3">
              <h5>Send Seller a Message</h5>
              <p>
                If you want to ask about Pickup Instructions or if you have
                questions about the item
              </p>
              <TextField
                id="outlined-multiline-static"
                label="Message"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary">Add To Cart</Button>{" "}
            <Button color="secondary" onClick={toggleModal}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default AddToCartModal;
