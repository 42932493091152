import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Placeholder from "../../../assets/images/Placeholder.png";
import { useSelector } from "react-redux";
import {
  duplicateRequest,
  saveBizzNeed,
  statusOfRequest,
} from "../../../components/Header/Data";
import { FaMapMarker } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ActiveNeeds = ({ activeNeeds, fetchData }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const [openMenuId, setOpenMenuId] = useState(null);
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const toggleMenu = (adId) => {
    setOpenMenuId(openMenuId === adId ? null : adId);
  };
  const onDuplicate = async (need) => {
    const needId = {
      needId: need.Id,
    };
    await duplicateRequest(needId);
    toast.success(`${need.Title} Duplicate created`);
    fetchData();
  };
  const onEdit = async (need) => {
    if (isAdmin) {
      const url = `/#/needs-requests/request?needId=${need.Id}`;
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      navigate(`/needs-requests/request?needId=${need.Id}`);
    }

    window.scroll(0, 0);
  };
  const onShare = async (need) => {};
  const onDelete = async (need) => {
    const data = {
      Status: 2,
      createdBy: user.UserID,
    };
    await statusOfRequest(data, need.Id);
    toast.success(`${need.Title} Deleted`);
    fetchData();
  };
  const onPause = async (need) => {
    const userId = { createdBy: user.UserID, Status: 3 };
    await statusOfRequest(userId, need.Id);
    toast.success(`${need.Title} Paused`);
    fetchData();
  };
  function formatDate(isoString) {
    const date = new Date(isoString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  }
  const handleRow = async (value, need) => {
    const bizzValue = value;

    setRows((prevRows) => ({
      ...prevRows,
      [need.Id]: bizzValue,
    }));
    const formData = {
      needId: need.Id,
      bizzValue: bizzValue,
    };

    await saveBizzNeed(formData);
  };

  useEffect(() => {
    const initialRows = activeNeeds.reduce((acc, need) => {
      acc[need.Id] = need.bizzValue || "0"; // Replace with your logic to get bizzValue
      return acc;
    }, {});
    setRows(initialRows);
  }, [activeNeeds]);
  return (
    <Row className="ads" style={{ margin: "0 7px", padding: "20px" }}>
      {activeNeeds.map((need) => (
        <Row key={need.Id} className="need-card">
          <Col className="img needs-image p-0">
            <img
              className="w-100"
              style={{ height: "185px" }}
              src={
                need.Photo && need.Photo.length > 0 && need.Photo[0] !== ""
                  ? JSON.parse(need.Photo)[0]
                  : Placeholder
              }
              alt={need.Title}
            />
          </Col>
          <Col className="need-info">
            <Row>
              <Col
                sm="6"
                xs="12"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p className="pt-2">{need.categoryName}</p>
              </Col>
              <Col sm="6" xs="12" className="action-buttons">
                {user.Role === "admin" && (
                  <div className="d-flex justify-content-between">
                    <select
                      style={{
                        width: "167px",
                        height: "32px",
                        borderRadius: "5px",
                      }}
                      className="btn btn-success btn-sm mx-1 text-white text-start text-nowrap form-select"
                      value={rows[need.Id] || "0"} // Bind to the specific row value for this service
                      onChange={(e) => handleRow(e.target.value, need)}
                    >
                      <option value="0">Add to Bizz Listing</option>
                      <option value="1">Row 1</option>
                      <option value="2">Row 2</option>
                      <option value="3">Row 3</option>
                      <option value="4">Deselect</option>
                    </select>
                  </div>
                )}
                {user.RoleId === 1 && (
                  <button
                    className="px-2 btn-sm mx-1"
                    style={{
                      backgroundColor: "#FFD06D",
                      color: "#000",
                      height: "32px",
                    }}
                    onClick={() => onPause(need)}
                  >
                    Pause
                  </button>
                )}{" "}
                <button
                  className="edit-button btn-sm mx-1"
                  style={{
                    height: "32px",
                  }}
                  onClick={() => onEdit(need)}
                >
                  Edit
                </button>
                <div className="menu-container mx-1">
                  <span
                    className="menu-toggle"
                    onClick={() => toggleMenu(need.Id)}
                  >
                    ⋮
                  </span>
                  {openMenuId === need.Id && (
                    <div className="menu">
                      <p onClick={() => onDuplicate(need)}>Duplicate</p>
                      <p onClick={() => onShare(need)}>Share</p>
                      <p onClick={() => onDelete(need)}>Delete</p>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <div className="row">
              <div className="col-sm-6">
                <h6>{need.Title}</h6>
                <p>
                  <FaMapMarker /> {need.Zipcode}
                </p>
              </div>
              <div className="col-sm-6">
                {isAdmin && (
                  <div className="overflow-hidden">
                    <p className="mb-0">Email: {need?.Email}</p>
                    <p className="mb-0">Full Name: {need?.FullName}</p>
                    <p className="mb-0">
                      Ad Posted: {formatDate(need?.createdAt)}
                    </p>
                    <p className="mb-0">
                      Last Updated: {formatDate(need?.updatedAt)}
                    </p>
                    <p className="mb-0">Call: {need?.Phone}</p>
                    <p className="mb-0">Extension # {need?.dialExtension}</p>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="need-footer">
              <p>
                <FaMapMarker /> {need.Zipcode}
              </p>
            </div> */}
          </Col>
        </Row>
      ))}
    </Row>
  );
};

export default ActiveNeeds;
