import React, { useEffect, useState } from "react";
import "./footer.css";
import Sikh from "../../../src/assets/images/img/sikh.png";
import { Link } from "react-router-dom";
import { BsYoutube, BsFacebook, BsInstagram } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import Magisticlogo from "../../../src/assets/images/img/megisticLogo2.png";
import { useSelector } from "react-redux";

import { toast } from "react-toastify";
import { needCall } from "../Header/Data";
import { FaPhoneAlt } from "react-icons/fa";

import { listGurudwaras } from "../Header/Data";
import { Button, Modal, ModalBody, FormGroup, Label, Input } from "reactstrap";
import { GrFormClose } from "react-icons/gr";

const Footer = () => {
  const [privacy, setPrivacy] = useState("");
  const [shipping, setShipping] = useState("");
  const [isOpen, setIsOpen] = useState("");
  const [gurdwaras, setGurdwaras] = useState([]);
  const location = useLocation();
  // const isHomePage = location.pathname === "/" || location.pathname === "/home";
  const isBrowsePage = location.pathname === "/weddingvendors";
  console.log("location path");
  const route_path = location.pathname;
  let abs_path = route_path.replace(/[^a-zA-Z ]/g, "");
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [modal, setModal] = useState(false);
  const [gurdwaraId, setGurdwaraId] = useState(null); // Initialize as null or "".
  const [gurdwaraName, setGurdwaraName] = useState("");
  const toggleModal = () => setModal(!modal);

  const handleClick = (e) => {
    const selectedValue = e.target.value;
    const selectedGurdwaraData = gurdwaras.find(
      (gurudwara) => gurudwara.Id === parseInt(selectedValue)
    ); // Use parseInt to convert to number

    setGurdwaraId(selectedValue);
    setGurdwaraName(selectedGurdwaraData ? selectedGurdwaraData.Title : ""); // Set title based on selection
  };

  const handleSubmit = () => {
    const cleanedGurdwaraName = gurdwaraName.replace(/\s+/g, ""); // Remove all spaces
    window.open(`#/bookGurdwara/${cleanedGurdwaraName}?id=${gurdwaraId}`);
    toggleModal();
  };
  const handleZoomWelcomePageClick = () => {
    window.scrollTo(0, 0);
  };
  const fetchData = async () => {
    const gurudwaras = await listGurudwaras();
    console.log(gurudwaras, "gurudwaras");
    setGurdwaras(gurudwaras);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <footer className="py-4 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="office__info pe-5">
                {/* <address>{address}</address> */}
                {isBrowsePage ? (
                  <img
                    className="d-lg-block wc-logo2 "
                    src={Magisticlogo}
                    alt="logo"
                  />
                ) : (
                  <img className="d-lg-block wc-logo" src={Sikh} alt="logo" />
                  // <img
                  //   className="d-lg-block wc-logo"
                  //   src={wedLogo}
                  //   alt="logo"
                  // />
                )}
                <div className="social_icons d-flex align-items-center gap-15 mt-4">
                  <a
                    href="https://www.youtube.com/channel/UCDktIvmaFNB5rcAOevDXUrw"
                    target="_blank"
                  >
                    <BsYoutube className="fs-5" />
                  </a>
                  <a
                    href="https://www.facebook.com/people/Indian-Wedding-Vendors-LLC/100088337712267/"
                    target="_blank"
                  >
                    <BsFacebook className="fs-5" />
                  </a>
                  <a
                    href="https://www.instagram.com/myindiancommunity/"
                    target="_blank"
                  >
                    <BsInstagram className="fs-5" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-links d-flex flex-column">
                <Link className=" text-dark py-2 mb-1" to="">
                  Sell
                </Link>
                <Link
                  className=" text-dark py-2 mb-1"
                  to={`/weddingvendors`}
                  // to={`about/privacy/${privacy}`}
                >
                  Browse Listing
                </Link>
                <Link
                  className=" text-dark py-2 mb-1"
                  to={`about/shipping/${shipping}`}
                >
                  Browse Action
                </Link>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-links d-flex flex-column">
                <Link className=" text-dark py-2 mb-1" to="/privacypolicies">
                  Privacy Policies
                </Link>
                <Link className=" text-dark py-2 mb-1" to="aboutus/">
                  About Us
                </Link>
                <Link className=" text-dark py-2 mb-1" to="contact/us/ll">
                  Contact Us
                </Link>
                <Link className=" text-dark py-2 mb-1" to="pricing/">
                  Pricing
                </Link>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <Link to="/need/request" className="text-decoration-underline">
                Post Your Ad
              </Link>
              <br />
              {/* <button
                type="button"
                className="btn btn-sm btn-success mt-3 "
                onClick={handleClick}
              >
                <span className="mx-3">Call </span>
              </button> */}
              <button
                type="button"
                className="btn btn-sm btn-primary mt-3"
                onClick={toggleModal}
              >
                <span className="mx-1">Book a Gurdwara</span>
              </button>
              <Modal isOpen={modal} toggle={toggleModal}>
                <ModalBody>
                  <div className="d-flex justify-content-between">
                    <h5 className="mt-2">Select Gurdwara</h5>
                    <h5 className="btn btn-sm" onClick={toggleModal}>
                      <GrFormClose size={30} />
                    </h5>
                  </div>
                  <FormGroup>
                    <Label for="gurdwaraSelect">Choose a Gurdwara</Label>
                    <Input
                      type="select"
                      name="gurdwara"
                      id="gurdwaraSelect"
                      value={gurdwaraId || ""}
                      onChange={(e) => handleClick(e)}
                    >
                      <option value="0">Select Gurdwara</option>
                      {gurdwaras.map((gurudwara) => (
                        <option key={gurudwara.Id} value={gurudwara.Id}>
                          {gurudwara.Title}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <div className="d-flex justify-content-end gap-10">
                    <Button color="primary" onClick={handleSubmit}>
                      Submit
                    </Button>
                  </div>
                </ModalBody>
              </Modal>
            </div>
            {isBrowsePage ? (
              <p className="footer__copyright mb-0 text-dark align-items-center">
                &copy; Majestic Events All rights reserved
              </p>
            ) : (
              <p className="footer__copyright mb-0 text-dark align-items-center">
                &copy; My Sikh Community.ORG All rights reserved
              </p>
            )}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
