import { FaCartArrowDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cartActions } from "../../redux/slices/cartSlice";
import React from "react";
import axiosClient from "../../axios-client";
import { Badge, IconButton, styled } from "@mui/material";
import { toast } from "react-toastify";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
export const getMyCartItems = async (userId, dispatch) => {
  if (!userId) {
    return;
  }
  try {
    const response = await axiosClient.get(`item/getCartItem/${userId}`);
    if (response.status === 200) {
      dispatch(cartActions.setMyCartItems(response.data.cartList));
    }
  } catch (error) {
    console.log(error, "Error fetching cart items");
  }
};
export const MyCart = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const myCartItems = useSelector((state) => state.cart.myCartItems);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (islogin && user) {
      getMyCartItems(user.UserID, dispatch);
    }
  }, [islogin]);
  const handleCartClick = () => {
    islogin
      ? navigate("/dashboardpage/orders?tab=items-in-cart")
      : navigate("/login");
  };
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 1,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  }));
  return (
    <IconButton aria-label="cart" onClick={handleCartClick}>
      <StyledBadge
        badgeContent={myCartItems.length}
        color="secondary"
        showZero
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <FaCartArrowDown size={25} />
      </StyledBadge>
    </IconButton>
  );
};
