import React from "react";
import { FadeLoader } from "react-spinners";
import ItemCard from "../ItemCards";

const PPItemsForSale = ({
  loading,
  profileItems,
  setProfileItems,
  getAllIfs,
}) => {
  return (
    <div className="people-profile-items mb-3">
      <h6>My Items For Sale</h6>
      <hr />
      {loading && (
        <p>
          <FadeLoader
            color={"#36D7B7"}
            loading={loading}
            className="top-100 start-50 translate-middle"
          />
        </p>
      )}
      {!loading && profileItems && profileItems.length > 0 ? (
        <div
          style={{
            overflowX: "auto",
            display: "flex",
            padding: "10px",
            whiteSpace: "nowrap",
          }}
          className="my-item-card"
        >
          <ItemCard
            profileItems={profileItems}
            setProfileItems={setProfileItems}
            getAllIfs={getAllIfs}
          />
        </div>
      ) : (
        <div className="text-center">No Items Avaliable</div>
      )}
    </div>
  );
};

export default PPItemsForSale;
