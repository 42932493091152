import React, { useEffect } from "react";
import { fetchMyServices } from "../../../components/Header/Data";
import BwvList from "../../../components/Header/WeddingVendors/BwvList";

const PPServices = ({ userId }) => {
  const [services, setServices] = React.useState([]);
  const [showAll, setShowAll] = React.useState(false); // State to manage showing all services

  React.useEffect(() => {
    const reqData = {
      createdBy: userId,
    };
    const getServices = async () => {
      const fetchedServices = await fetchMyServices(reqData);
      setServices(fetchedServices); // Store all fetched services
    };
    getServices();
  }, [userId]);

  const recentServices = services.slice(-2); // Get the recent services

  // Toggle function to show more or less services
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="mb-3">
      <h6>My Services/Products Listing (I'm offering)</h6>
      <div className="people-serive">
        <BwvList currentCards={showAll ? services : recentServices} />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button onClick={toggleShowAll}>
          {showAll ? "Show Less" : "Show More..."}
        </button>
      </div>
    </div>
  );
};

export default PPServices;
