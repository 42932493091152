import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { FaWindowClose } from "react-icons/fa";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { GetItemOrderById } from "../../../components/Header/Data3";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider"; // Add this line
import { getAmount } from "../../GlobalFunctions/Global";
import axiosClient from "../../../axios-client";
import { toast } from "react-toastify";
import { Box, TextField } from "@mui/material";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const NotifyCustomer = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const { id: orderId } = useParams();
  const [open, setOpen] = React.useState(true);
  const navigate = useNavigate();
  const [itemOrder, setItemOrder] = React.useState();
  const [orderInstruction, setOrderInstruction] = React.useState("");

  const Notify = async () => {
    if (!user) navigate("/login");
    let formData = {
      orderStatus:
        itemOrder?.delivery_option == 3 ? `READY TO PICK` : `SHIPPED`,
      updatedBy: user.UserID,
      orderInstruction: orderInstruction,
    };
    try {
      const response = await axiosClient.put(
        `item/StatusUpdateItemOrder/${orderId}`,
        formData
      );
      if (response.data.statusCode === 200) {
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.log(error);
    }
    setOpen(false);
    navigate(`/items-for-sale`);
  };
  const handleClose = () => {
    setOpen(false);
    navigate(`/items-for-sale`);
  };
  const getOrderById = async () => {
    try {
      const response = await GetItemOrderById(orderId);
      setItemOrder(response[0]);
      console.log(response, "response");
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    if (!islogin) {
      navigate("/login");
    }
    getOrderById();
  }, []);
  const deliveryOptionsMap = {
    1: { label: "Standard 3 Days Delivery", cost: "$5.00" },
    2: { label: "Same Day Delivery", cost: "$10.00" },
    3: { label: "Pick Up In Person", cost: "Free" },
  };

  // Assuming `itemOrder` is defined and contains the `delivery_option`
  const deliveryOption = deliveryOptionsMap[itemOrder?.delivery_option] || {
    label: "Unknown Delivery Option",
    cost: "N/A",
  };
  return (
    <React.Fragment>
      <div style={{ minHeight: "90vh" }}></div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Notify Customer
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <FaWindowClose />
        </IconButton>
        <DialogContent dividers>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <img
              src={itemOrder?.image}
              alt={itemOrder?.itemname}
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "8px",
                marginRight: "20px",
              }}
            />
            <div>
              <Typography gutterBottom variant="h5" component="div">
                {itemOrder?.itemname}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Price:{" "}
                <span className="text-primary fw-bold">
                  ${getAmount(itemOrder?.price, itemOrder?.delivery_option)}
                </span>
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Order Status:{" "}
                <span
                  className={`px-2 text-center text-white ${
                    itemOrder?.order_status === `COMPLETED`
                      ? `bg-success`
                      : `bg-warning`
                  }`}
                  style={{
                    // fontSize: "8px",
                    borderRadius: "5px",
                  }}
                >
                  {itemOrder?.order_status}
                </span>
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Delivery Option:{" "}
                <span className="text-danger fw-bold">
                  {deliveryOption.label}
                </span>
              </Typography>
              {itemOrder?.delivery_option == 3 ? (
                <Typography variant="body2" color="textSecondary">
                  Pickup Address: {itemOrder?.address}
                </Typography>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Delivery Address: {itemOrder?.address}
                </Typography>
              )}
            </div>
          </div>
          <Divider style={{ margin: "10px 0" }} />
          <Typography variant="h6" component="div" gutterBottom>
            Customer Details
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Name: {itemOrder?.customerfirstname} {itemOrder?.customerlastname}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Phone: {itemOrder?.customerphone}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Email: {itemOrder?.customeremail}
          </Typography>
          <Divider style={{ margin: "10px 0" }} />
          <Typography variant="h6" component="div" gutterBottom>
            Delivery Instructions
          </Typography>
          <Box>
            <TextField
              id="outlined-multiline-flexible"
              label="Delivery Instructions"
              multiline
              maxRows={4}
              sx={{ width: "100%" }}
              variant="outlined"
              placeholder="Enter delivery instructions"
              name="orderInstruction"
              className="form-control"
              type="text"
              required={itemOrder?.delivery_option == 3}
              value={orderInstruction}
              onChange={(e) => setOrderInstruction(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={Notify}>
            {itemOrder?.delivery_option == 3 ? ` Notify Pickup` : `Shipped`}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};
