import React from "react";
import { Col, Row } from "reactstrap";
import Placeholder from "../../../assets/images/Placeholder.png";
import { useSelector } from "react-redux";
import { FaMapMarker } from "react-icons/fa";

const Bizz_Listings = ({ bizzNeeds, isLoading }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";
  function formatDate(isoString) {
    const date = new Date(isoString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  }
  return (
    <Row className="ads" style={{ margin: "0 7px", padding: "20px" }}>
      {bizzNeeds.length > 0 ? (
        <>
          {" "}
          {bizzNeeds.map((need) => (
            <Row key={need.Id} className="need-card my-1">
              <Col className="img needs-image p-0">
                <img
                  className="w-100"
                  style={{ height: "185px" }}
                  src={
                    need.Photo && need.Photo.length > 0 && need.Photo[0] !== ""
                      ? JSON.parse(need.Photo)[0]
                      : Placeholder
                  }
                  alt={need.Title}
                />
              </Col>
              <Col className="need-info">
                <Row>
                  <Col
                    sm="6"
                    xs="12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <p className="pt-2">{need.categoryName}</p>
                  </Col>
                  <Col sm="6" xs="12" className="action-buttons">
                    <button className="btn btn-primary btn-sm mx-2">
                      {" "}
                      {need?.bizzValue === 1
                        ? "Row 1"
                        : need?.bizzValue === 2
                        ? "Row 2"
                        : need?.bizzValue === 3 && "Row 3"}
                    </button>
                  </Col>
                </Row>
                <div className="row">
                  <div className="col-sm-6">
                    <h6>{need.Title}</h6>
                    <p>
                      <FaMapMarker /> {need.Zipcode}
                    </p>
                  </div>
                  <div className="col-sm-6">
                    {isAdmin && (
                      <div>
                        <p className="mb-0">Email: {need?.Email}</p>
                        <p className="mb-0">Full Name: {need?.FullName}</p>
                        <p className="mb-0">
                          Ad Posted: {formatDate(need?.createdAt)}
                        </p>
                        <p className="mb-0">
                          Last Updated: {formatDate(need?.updatedAt)}
                        </p>
                        <p className="mb-0">Call: {need?.Phone}</p>
                        <p className="mb-0">
                          Extension # {need?.dialExtension}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="need-footer">
              <p>
                <FaMapMarker /> {need.Zipcode}
              </p>
            </div> */}
              </Col>
            </Row>
          ))}
        </>
      ) : (
        !isLoading && <div className="text-center my-4">No Bizz Lisings</div>
      )}
    </Row>
  );
};

export default Bizz_Listings;
