import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allNeeds: [],
  myNeeds: [],
  engagedNeeds: [],
};

const myNeedsSlice = createSlice({
  name: "myNeeds",
  initialState,
  reducers: {
    setAllNeeds: (state, action) => {
      state.allNeeds = action.payload;
    },
    setMyNeeds: (state, action) => {
      state.myNeeds = action.payload;
    },
    setEngagedNeeds: (state, action) => {
      state.engagedNeeds = action.payload;
    },
  },
});

export const { setAllNeeds, setEngagedNeeds, setMyNeeds } =
  myNeedsSlice.actions;
export default myNeedsSlice.reducer;
