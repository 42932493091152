import React, { useEffect, useState } from "react";
import { Row, Col, Input, Label, Button, FormGroup, Alert } from "reactstrap";
import { FaStar } from "react-icons/fa";
import Modal from "react-modal";
import { sendMessage, MakeAnOffer } from "../Data";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import userIcon from "../../../assets/images/user-icon.png";
// import userIcon from '../../assets/images/user-icon.png';

const MsgModal = ({ isOpen, closeModal, selectedCard, userDetails }) => {
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [message, setMessage] = useState("");
  const [budget, setBudget] = useState(null);
  const [error, setError] = useState("");
  const MessageSend = async () => {
    // createdBy is sender
    // customerId is reciver or owner
    // ownerId is owner of the need /service
    const senderId = user.UserID;
    const recipientId = selectedCard ? selectedCard.createdBy : userDetails.Id;
    const messageForm = {
      customerId: recipientId,
      postId: selectedCard ? selectedCard.Id : `0`,
      postType: 1, // message 1 or requestQuote 2 or offer my service 3
      Description: message,
      messageStatus: 8,
      createdBy: senderId,
      roleId: user.RoleId,
      ownerId: selectedCard ? selectedCard.createdBy : `0`,
      userName: user.UserName,
      Email: user.Email,
      serviceNeed: "text",
    };
    if (!messageForm.Description) {
      // toast.error("Enter Message");
      setError("Please Enter Text Message...!");
      return;
    }
    await sendMessage(messageForm);
    toast.success("Message sent");
    setMessage("");
    closeModal();
  };

  const handlebudget = (e) => {
    let value = e.target.value.replace(/[^0-9.]/g, "");
    const decimalCount = (value.match(/\./g) || []).length;
    if (decimalCount > 1) {
      value = value.substring(0, value.lastIndexOf("."));
    }
    if (value.length <= 8) {
      const parts = value.split(".");
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2);
        value = parts.join(".");
      }
      setBudget(value);
    }
  };
  const close = () => {
    closeModal();
    setError("");
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={close}
      onRequestClose={close}
      className="hire-modal"
      style={{
        content: {
          width: "50%",
          margin: "auto",
          backgroundColor: "#fff",
          padding: "40px",
          position: "absolute",
          top: "30px",
          left: "50%",
          transform: "translateX(-50%)",
          border: "1px solid gray",
          borderRadius: "20px",
        },
      }}
      size="lg"
    >
      <div className="d-flex justify-content-end">
        <button type="button" className="btn-close" onClick={close}></button>
      </div>
      <div className="modal-body">
        <div className="modal-body ">
          <div className="d-flex flex-row align-items-center pb-3">
            <div style={{ width: "50px", height: "50px", marginRight: "30px" }}>
              <img
                src={
                  selectedCard && selectedCard.profilePhoto
                    ? selectedCard.profilePhoto
                    : userDetails && userDetails.Photo
                    ? userDetails.Photo
                    : userIcon
                }
                // alt="Selected Card"
                style={{ height: "100%", width: "100%", borderRadius: "50%" }}
              />
            </div>
            <div>
              <h5>{selectedCard?.userName || userDetails?.UserName}</h5>
            </div>
          </div>

          <FormGroup>
            <Label>Message </Label>
            <Input
              type="textarea"
              // value={message}
              placeholder="Type message & please mention your phone number..."
              onChange={(e) => setMessage(e.target.value)}
            />
          </FormGroup>
          {error && (
            <Alert color="danger" className="text-center">
              {error}
            </Alert>
          )}
          <div className="d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                MessageSend();
              }}
            >
              Send message
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MsgModal;
