import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import axios from "axios";

const UsersMap = () => {
  const { finalUsers } = useSelector((state) => state.peopleInCom);
  const [locations, setLocations] = useState([]);

  // Load Google Maps
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDROtwqL701TVqixE8NPJAqxu6h5HIDWVE", // Replace with your API key
  });

  useEffect(() => {
    const fetchCoordinates = async () => {
      const newLocations = [];
      for (let user of finalUsers) {
        const { ZipCode, Photo } = user;
        console.log(user, "geocode-user");
        try {
          const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${ZipCode}&key=AIzaSyDROtwqL701TVqixE8NPJAqxu6h5HIDWVE`
          );
          console.log(response.data); // Debug the API response
          if (response.data.results && response.data.results.length > 0) {
            const { lat, lng } = response.data.results[0].geometry.location;
            newLocations.push({ lat, lng, Photo });
          } else {
            console.error(`No geocoding results for zip code: ${ZipCode}`);
          }
        } catch (error) {
          console.error(
            "Error fetching coordinates for zipcode:",
            ZipCode,
            error
          );
        }
      }
      setLocations(newLocations);
    };

    if (finalUsers && finalUsers.length > 0) {
      fetchCoordinates();
    }
  }, [finalUsers]);

  if (!isLoaded) {
    return <div>Loading Map...</div>;
  }

  return (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "500px" }}
      center={{ lat: 37.7749, lng: -122.4194 }} // Set a default center
      zoom={8}
    >
      {locations.map((loc, index) => (
        <Marker
          key={index}
          position={{ lat: loc.lat, lng: loc.lng }}
          title={finalUsers[index].FirstName}
          label={finalUsers[index].FirstName}
          onClick={() => {
            console.log(`Marker clicked: ${finalUsers[index].FirstName}`);
          }}
        />
      ))}
    </GoogleMap>
  );
};

export default UsersMap;
