import React, { useEffect, useState } from "react";
import "./Request.css";
import { FaArrowLeft } from "react-icons/fa";
import { Col, Row } from "reactstrap";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  fetchCategories,
  fetchSubCategories,
  fetchItems,
  fetchLCAById,
} from "../../../components/Header/Data";
import { useDispatch, useSelector } from "react-redux";
import RequestNeed from "./RequestNeed";
import {
  resetFormState,
  setAdPosting,
  setAlternativePhoneOne,
  setAlternativePhoneTwo,
  setAvailableFrom,
  setAvailableTo,
  setCategoryAnswer,
  setCategoryId,
  setCategoryName,
  setCreatedBy,
  setCreatedEmail,
  setDescription,
  setDialExtension,
  setEmail,
  setEngagedCouple,
  setEventDate,
  setEventDetails,
  setEventTime,
  setFullName,
  setGurudwara,
  setItemId,
  setItemName,
  setNotifyItemList,
  setPhone,
  setPhoto,
  setSubCategoryId,
  setSubCategoryName,
  setTerms,
  setTitle,
  setUpdatedBy,
  setZipcode,
} from "../../../redux/slices/RequestFormSlice";

const Request = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const location = useLocation();
  const needId = new URLSearchParams(location.search).get("needId");
  const [editMode, setEditMode] = useState([]);
  const [selectedCat, setSelectedCat] = useState(null);

  const formData = useSelector((state) => state.requestForm);
  console.log(formData, "formData");
  console.log(needId, "needId");
  const dispatch = useDispatch();
  const fetchData = async () => {
    try {
      let categoriesData = await fetchCategories();
      let subCategoriesData = await fetchSubCategories();
      setCategories(categoriesData);
      setSubCategories(subCategoriesData);
    } catch (error) {
      console.error("Error fetching categories:", error.message);
    }
  };
  useEffect(() => {
    fetchData();
    dispatch(resetFormState());
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    if (needId) {
      const fetchLca = async () => {
        const responseData = await fetchLCAById(needId);
        const response = responseData[0];
        console.log(response, "response");
        console.log(responseData, "responseData");
        if (response) {
          setEditMode(response);
          console.log(response, "response");
          setSelectedCat(response.categoryId);
          dispatch(setCategoryId(response.categoryId));
          dispatch(setSubCategoryId(response.subCategoryId));
          dispatch(setItemId(response.itemId));
          dispatch(setCategoryName(response.categoryName));
          dispatch(setSubCategoryName(response.subCategoryName));
          dispatch(setItemName(response.itemName));
          dispatch(setTitle(response.Title));
          dispatch(setDescription(response.Description));
          dispatch(setPhoto(response.Photo));
          dispatch(setZipcode(response.Zipcode));
          dispatch(setFullName(response.FullName));
          dispatch(setPhone(response.Phone));
          dispatch(setDialExtension(response.dialExtension));
          dispatch(setGurudwara(response.Gurudwara));
          dispatch(setEventDate(response.eventDate));
          dispatch(setEventTime(response.eventTime));
          dispatch(setEventDetails(response.eventDetails));
          dispatch(setAvailableFrom(response.availableFrom));
          dispatch(setAvailableTo(response.availableTo));
          dispatch(setEngagedCouple(response.engagedCouple));
          dispatch(setTerms(response.Terms));
          dispatch(setAdPosting(response.adPosting));
          dispatch(setEmail(response.Email));
          dispatch(setCategoryAnswer(response.categoryAnswer));
          dispatch(setNotifyItemList(response.notifyItemList));
          dispatch(setAlternativePhoneOne(response.alternativePhoneOne));
          dispatch(setAlternativePhoneTwo(response.alternativePhoneTwo));
          // dispatch(setCreatedEmail(response.Email));
        } else {
          dispatch(resetFormState());
          navigate(-1);
          return;
        }
      };
      fetchLca();
    } else {
      dispatch(resetFormState());
    }
  }, [needId]);
  // Check if logged user is admin and URL has needId
  const isAdminWithNeedId = isAdmin && !!needId;
  return (
    <div className="container pt-5" style={{ position: "relative" }}>
      {/* Back button positioned outside of the column structure */}
      {!(isAdmin && !!needId) && (
        <p
          onClick={() => {
            navigate(-1);
            dispatch(resetFormState());
          }}
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "19px",
            left: "20px",
            fontSize: "18px",
            color: "#000",
            zIndex: 999,
          }}
        >
          <FaArrowLeft
            style={{
              marginRight: "6px",
              fontSize: "18px",
              marginTop: "-3px",
            }}
          />
          Back
        </p>
      )}
      {/* Column structure for the main content */}
      <div className="request-container">
        <Row className="px-lg-5 px-2">
          <Col>
            <h5 className="text-center" style={{ fontSize: "30px" }}>
              <b>Post Your Ad</b>
            </h5>
            <Col>
              <RequestNeed
                categories={categories}
                subCategories1={subCategories}
                editMode={editMode}
                needId={needId}
                selectedCat={selectedCat}
                setSelectedCat={setSelectedCat}
              />
            </Col>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Request;
