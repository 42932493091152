import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { FaTrashAlt } from "react-icons/fa";
import { VscKebabVertical } from "react-icons/vsc";
import { Button, Menu, MenuItem } from "@mui/material";
import { FadeLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../../axios-client";
import { toast } from "react-toastify";

const ItemsInStock = ({ ItemsUnSold, fetchData, loading }) => {
  const navigate = useNavigate();
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";
  const [sending, setSending] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  console.log(ItemsUnSold, "ItemsUnSold");
  const handleMenuOpen = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };
  console.log(selectedItem, "item edit");
  const onEdit = () => {
    setAnchorEl(null);
    navigate(`/create-item`, { state: selectedItem });
  };
  const onDelete = async () => {
    setSending(true);
    const request = {
      updatedBy: user.UserId,
      itemStatus: 2,
      deletedBy: user.UserId,
    };
    try {
      const response = await axiosClient.put(
        `item/StatusUpdateItem/${selectedItem.Id}`,
        request
      );
      if (response.data.statusCode === 200) {
        toast.success(`${selectedItem.name} Deleted`);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setSending(false);
    setAnchorEl(null);
  };
  return (
    <div>
      {loading ? (
        <FadeLoader // FadeLoader component
          css={{ margin: "0 auto" }}
          color={"#36D7B7"}
          loading={loading}
          className="position-absolute translate-middle"
          style={{ left: "60%", top: "40%" }}
        />
      ) : (
        <div>
          {ItemsUnSold?.length > 0 ? (
            <div
              className="my-item-container mt-4 pb-3"
              style={{ marginLeft: "0" }}
            >
              {ItemsUnSold.map((item) => (
                <div
                  key={item.id}
                  className="my-item-card rounded px-0 mb-0 mt-3"
                  style={{ width: "97%" }}
                >
                  <Row>
                    <Col sm={3} xs={12} className="p-0 position-relative">
                      <img
                        className="mw-100 object-fit-cover border rounded mh-100 img-fluid img-thumbnail"
                        src={
                          item.image && item.image !== ""
                            ? item.image
                            : "https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                        }
                        // src="https://st3.depositphotos.com/6672868/13701/v/600/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                        alt={item.name}
                        style={{ width: "400px", height: "150px" }}
                      />
                    </Col>
                    <Col sm={9} xs={12} className="p-3 position-relative">
                      <Row className="top-0 end-0 pe-1">
                        <Col sm={7} xs={12}>
                          <h4 style={{ display: "inline" }}>{item.name}</h4>
                        </Col>
                        <Col
                          sm={5}
                          xs={12}
                          className="position-relative text-end cursor-pointer"
                        >
                          <span
                            className={`qty-status ${
                              item?.quantity > 0 ? `in-stock` : `out-stock`
                            } px-2 text-center`}
                          >
                            {item?.quantity > 0 ? `In Stock` : `Out Of Stock`}
                          </span>
                          <span
                            style={{ fontSize: "20px", cursor: "pointer" }}
                            onClick={(event) => handleMenuOpen(event, item)}
                          >
                            <VscKebabVertical style={{ marginTop: "2px" }} />
                          </span>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                          >
                            <MenuItem onClick={() => onEdit(item)}>
                              Edit
                            </MenuItem>
                            <MenuItem onClick={() => onDelete(item)}>
                              Delete
                            </MenuItem>
                          </Menu>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-between">
                        <p>{item.description}</p>
                      </div>
                      <h6 style={{ color: "#616bff" }}>$ {item.price}</h6>
                      <h6>Items Quantity : {item.quantity}</h6>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          ) : (
            <div className="p-5 my-item-container text-center">No items</div>
          )}
        </div>
      )}
    </div>
  );
};

export default ItemsInStock;
