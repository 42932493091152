import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import React from "react";
import Button from "@mui/material/Button";

const ConfirmRemoveModal = ({ open, toggle, onConfirm }) => {
  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>Confirm Remove Items</ModalHeader>
      <ModalBody>
        Are you sure you want to remove the current items from your cart?
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onConfirm}>
          Yes, Remove
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          No, Keep Current Items
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmRemoveModal;
