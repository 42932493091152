import React, { useEffect, useState } from "react";
import { Row, Col, Input, FormGroup } from "reactstrap";
import { FaPlusCircle } from "react-icons/fa";
import imageCompression from "browser-image-compression";
import uploadToS3 from "../../../utils/awsS3Upload";
import { useDispatch, useSelector } from "react-redux";
import { setPhoto } from "../../../redux/slices/RequestFormSlice";

const NeedPhotos = () => {
  const formData = useSelector((state) => state.requestForm);
  const dispatch = useDispatch();
  const [highlightedImages, setHighlightedImages] = useState(
    Array(7).fill(null)
  );
  useEffect(() => {
    const jsonPhoto =
      formData.Photo &&
      typeof formData.Photo === "string" &&
      JSON.parse(formData.Photo);
    if (jsonPhoto.length > 0) {
      setHighlightedImages(
        jsonPhoto.concat(Array(7 - jsonPhoto.length).fill(null))
      );
    } else {
      setHighlightedImages(Array(7).fill(null));
    }
  }, [formData.Photo]);
  const handleImageChange = async (index, event) => {
    const files = event.target.files;
    let folder = "need/";
    if (files.length > 0) {
      const imageFile = files[0];
      try {
        const imageUrl = await uploadToS3(imageFile, folder);
        const newImages = [...highlightedImages];
        newImages[index] = imageUrl;
        setHighlightedImages(newImages);
        dispatch(setPhoto(JSON.stringify(newImages.filter(Boolean))));
      } catch (error) {
        console.error("Error while compressing the image:", error);
      }
    }
  };
  return (
    <Row className="mb-3">
      <h5>Cover Image</h5>
      <span style={{ fontSize: "small" }}>
        {formData.engagedCouple === 0
          ? "Optional cover image of listing"
          : "Required is image of Individual(s) whose event it is. This will be cover image of listing"}
      </span>
      {highlightedImages.map((image, index) => (
        <Col className="image-input" key={index}>
          <FormGroup>
            <Input
              type="file"
              id={`imageUpload${index + 1}`}
              onChange={(e) => handleImageChange(index, e)}
              style={{ display: "none" }}
              name="Photo"
            />
          </FormGroup>
          <div
            className="image-box"
            onClick={() =>
              document.getElementById(`imageUpload${index + 1}`).click()
            }
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            {image && (
              <>
                <img
                  src={image}
                  alt={`Highlighted Image ${index + 1}`}
                  className="uploaded-image image-fluid rounded"
                  style={{ width: "80px", height: "80px" }}
                />
              </>
            )}
            {!image && <FaPlusCircle className="upload-icon" />}
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default NeedPhotos;
