import React, { useEffect, useState } from "react";
import BookGurdwaraStep1 from "./BookGurdwaraStep1";
import BookGurdwaraStep2 from "./BookGurdwaraStep2";
import BookGurdwaraStep3 from "./BookGurdwaraStep3";
import BookGurdwaraStep4 from "./BookGurdwaraStep4";
import { Button, Col, Row } from "reactstrap";
import {
  createGurudwaraBooking,
  detailsGurudwara,
  listGurudwaras,
} from "../../../../components/Header/Data";
import "../Gurdwara.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentStep,
  setGurdwaras,
  setPaypalID,
  setResponseId,
  setSelectedGurdwara,
  setServicePrices,
  setServiceTypes,
  setTerms,
  setTollFreeNumber,
} from "../../../../redux/slices/GurdwaraSlice";
import { toast } from "react-toastify";

const BookGurdwara = () => {
  const dispatch = useDispatch();
  // const [currentStep, setCurrentStep] = useState(1);
  const {
    currentStep,
    selectedGurdwara,
    fullName,
    email,
    selectedServiceType,
    mobileNumber1,
    mobileNumber2,
    mobileNumber3,
    eventDate,
    eventTime,
    eventLocation,
    additionalPeople,
    responseId,
    selectedServicePrice,
    paypalID,
    paymentStatus,
    serviceType,
    tollFreeNumber,
    terms,
  } = useSelector((state) => state.gurdwara);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = parseInt(queryParams.get("id"), 10); // Keep
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  console.log(user?.UserID, "UserID");
  // const [formData, setFormData] = useState({
  //   name: "",
  //   email: "",
  //   phone: "",
  //   eventType: "",
  //   eventDate: "",
  //   serviceType: "",
  // });
  // const [gurdwaras, setGurdwaras] = useState([]);
  // const [selectedGurdwara, setSelectedGurdwara] = useState(null);
  // const [serviceTypes, setServiceTypes] = useState([]);
  // const [selectedServicePrice, setSelectedServicePrice] = useState("");
  // const [serviceTypes, setServiceTypes] = useState([]);
  // const [servicePrices, setServicePrices] = useState({});
  // const [selectedServiceType, setSelectedServiceType] = useState("0");
  const fetchData = async () => {
    const gurudwaras = await listGurudwaras();
    const response = await detailsGurudwara(id);
    console.log(response.Data[0].paypalId, "paypalId");
    dispatch(setPaypalID(response.Data[0].paypalId));
    console.log(gurudwaras, "gurudwaras");
    dispatch(setGurdwaras(gurudwaras));
    dispatch(setTollFreeNumber(response.Data[0].tollFreeNumber));
    dispatch(setTerms(response.Data[0].Term));
    const matchedGurdwara = gurudwaras.find((gurdwara) => gurdwara.Id === id);
    if (matchedGurdwara) {
      dispatch(setSelectedGurdwara(matchedGurdwara));
    }

    const services = {};
    // gurudwaras?.forEach((gurdwara) => {
    //   if (gurdwara.serviceInfo) {
    const servicesFromGurdwara = JSON.parse(matchedGurdwara?.serviceInfo);
    servicesFromGurdwara?.forEach((service) => {
      if (!services[service.servicetype]) {
        services[service.servicetype] = service.serviceprice;
      }
    });
    // }
    // });
    console.log(services, "services");
    dispatch(setServiceTypes(Object.keys(services)));
    dispatch(setServicePrices(services));
  };
  useEffect(() => {
    fetchData();
  }, []);
  console.log(responseId, "responseId");
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <BookGurdwaraStep1
          // formData={formData}
          // setFormData={setFormData}
          // currentStep={currentStep}
          // setCurrentStep={setCurrentStep}
          // serviceTypes={serviceTypes}
          />
        );
      case 2:
        return (
          <BookGurdwaraStep2
          // formData={formData}
          // setFormData={setFormData}
          // currentStep={currentStep}
          // setCurrentStep={setCurrentStep}
          />
        );
      case 3:
        return (
          <BookGurdwaraStep3
          // formData={formData}
          // setFormData={setFormData}
          // currentStep={currentStep}
          // setCurrentStep={setCurrentStep}
          />
        );
      case 4:
        return (
          <BookGurdwaraStep4
          // formData={formData}
          // setFormData={setFormData}
          // currentStep={currentStep}
          // setCurrentStep={setCurrentStep}
          // selectedServicePrice={selectedServicePrice}
          />
        );
      // ... other cases
      default:
        return null;
    }
  };
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex
    return regex.test(email);
  };
  const validateStep = (step) => {
    switch (step) {
      case 1:
        if (!fullName) {
          toast.error("Please Enter Your Full Name");
          return false;
        }
        if (!email) {
          toast.error("Please Enter Your Email");
          return false;
        } else if (!validateEmail(email)) {
          toast.error("Please Enter a Valid Email");
          return false;
        }
        if (!mobileNumber1) {
          toast.error("Mobile Number 1 is required");
          return false;
        }
        if (mobileNumber1?.length < 10 && mobileNumber1?.length > 0) {
          toast.error("Enter valid Phone number");
          return false;
        }

        // if (!mobileNumber2) {
        //   toast.error("Mobile Number 2 is required");
        //   return false;
        // }
        if (mobileNumber2?.length < 10 && mobileNumber2?.length > 0) {
          toast.error("Enter valid Phone number");
          return false;
        }

        // if (!mobileNumber3) {
        //   toast.error("Mobile Number 3 is required");
        //   return false;
        // }
        if (mobileNumber3?.length < 10 && mobileNumber3?.length > 0) {
          toast.error("Enter valid Phone number");
          return false;
        }
        break;
      case 2:
        if (selectedServiceType === "0" || selectedServiceType === null) {
          toast.error("Please select a Service Type");
          return false;
        }
        if (!eventDate) {
          toast.error("Please select an Event Date");
          return false;
        }
        if (!eventTime) {
          toast.error("Please select an Event Time");
          return false;
        }
        if (!eventLocation) {
          toast.error("Please select an Event Location");
          return false;
        }
        break;
      // Add additional validation for other steps if necessary
      default:
        break;
    }
    return true; // If all validations pass
  };
  const handlePrev = (stepChange) => {
    const newStep = currentStep - stepChange;
    dispatch(setCurrentStep(newStep));
  };
  const handleNext = async (stepChange) => {
    const newStep = currentStep + stepChange; // Calculate the new step
    console.log(stepChange, "stepChange");
    if (newStep === 2) {
      // Validate Step 1 before moving to Step 2
      if (!validateStep(1)) {
        return; // Stop if validation fails
      }
    }

    if (newStep === 3) {
      // Validate Step 2 before moving to Step 3
      if (!validateStep(2)) {
        return; // Stop if validation fails
      }
    }
    // Only validate when moving to the next ste
    console.log(newStep, "newStep");

    // Call the API if moving from step 1 to step 2
    if (currentStep === 1) {
      const formData = {
        Name: fullName,
        Email: email,
        mobileOne: mobileNumber1,
        mobileTwo: mobileNumber2,
        mobileThree: mobileNumber3,
        createdBy: user ? user.UserID : 0,
        gurudwaraId: id,
        bookingStatus: currentStep,
        // Include any other data you want to send
      };

      try {
        // Replace this with your actual API call
        const response = await createGurudwaraBooking(formData);
        console.log("response", response.id);
        dispatch(setResponseId(response?.id));
        console.log("Data submitted successfully:", formData);
      } catch (error) {
        console.error("Error submitting data:", error);
        toast.error("Failed to submit data. Please try again.");
        return; // Stop navigation if there's an error
      }
    }

    if (currentStep === 2 && responseId) {
      const additionalFormData = {
        serviceType: selectedServiceType,
        eventDate: eventDate,
        eventTime: eventTime,
        eventLocation: eventLocation,
        additionalPeople: additionalPeople,
        updatedBy: user ? user.UserID : 0,
        gurudwaraId: id,
        Id: responseId, // Use the response ID from step 1
        bookingStatus: currentStep,
      };
      console.log(additionalFormData, "additionalFormData");
      try {
        const response = await createGurudwaraBooking(additionalFormData);
        console.log("Update response", response);
        // You can also handle any specific logic for the response here
      } catch (error) {
        console.error("Error updating data:", error);
        toast.error("Failed to update data. Please try again.");
        return; // Stop navigation if there's an error
      }
    }

    if (newStep <= 4) {
      dispatch(setCurrentStep(newStep));
    }
  };

  const handleSubmit = async () => {
    const formData = {
      fullName: fullName,
      email: email,
      serviceType: serviceType,
      mobileNumber1: mobileNumber1,
      mobileNumber2: mobileNumber2,
      mobileNumber3: mobileNumber3,
      eventDate: eventDate,
      eventTime: eventTime,
      eventLocation: eventLocation,
      additionalPeople: additionalPeople,
      serviceType: selectedServiceType,
      updatedBy: user ? user.UserID : 0,
      gurudwaraId: id,
      Id: responseId,
      bookingStatus: currentStep,
      paymentStatus: paymentStatus,
      paypalId: paypalID,
      Amount: selectedServicePrice,
    };
    console.log(formData, "formdata");
    try {
      // Replace this with your actual API call
      const response = await createGurudwaraBooking(formData);
      console.log("response", response);
      console.log("Data submitted successfully:", formData);
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to submit data. Please try again.");
      return; // Stop navigation if there's an error
    }
  };
  console.log(currentStep, "currentStep");

  useEffect(() => {
    if (paymentStatus === "COMPLETED" && currentStep === 4) {
      handleSubmit();
    }
  }, []);
  return (
    <div className="inter-font">
      <Row className="text-center my-4 mx-5">
        <Col className="position-relative">
          {selectedGurdwara && (
            <span className="fw-bold fs-4 text-center">
              {selectedGurdwara.Title} Bookings
            </span>
          )}
          <span
            className="position-absolute"
            style={{ right: "10%", marginTop: "-1%" }}
          >
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Get Help
              </button>
              <ul class="dropdown-menu get_help">
                <li>
                  <span class="dropdown-item">
                    ਕੀ ਤੁਹਾਡੇ ਕੋਲ ਕੋਈ ਸਵਾਲ ਹੈ? ਗੁਰਦੁਆਰੇ ਦੇ ਸਟਾਫ ਨੂੰ ਕਾਲ ਕਰੋ -
                    {tollFreeNumber}
                  </span>
                </li>
                <li>
                  <span class="dropdown-item">
                    Do you have a question? Call a staff at the Gurdwara -
                    {tollFreeNumber}
                  </span>
                </li>
              </ul>
            </div>
          </span>
        </Col>
      </Row>
      <div
        className={`booking_container`}
        style={{
          height: currentStep === 3 || currentStep === 4 ? "750px" : "405px",
        }}
      >
        <Row className="flex-column">
          <Col>
            <div>
              <div className="d-flex align-items-center justify-content-center">
                <div
                  className="page1"
                  style={{
                    height:
                      currentStep === 3 || currentStep === 4
                        ? "750px"
                        : "405px",
                  }}
                >
                  <div className="d-flex justify-content-between mx-5 my-4">
                    <div className="fw-bold fs-5">
                      {currentStep === 1 && (
                        <span style={{ textDecoration: "underline" }}>
                          Fill your Info
                        </span>
                      )}
                      {currentStep === 2 && (
                        <>
                          <span
                            style={{
                              color: "blue",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                            onClick={() => handlePrev(1)}
                          >
                            Fill your Info
                          </span>
                          {">"}
                          <span style={{ textDecoration: "underline" }}>
                            Event Details
                          </span>
                        </>
                      )}
                      {currentStep === 3 && (
                        <>
                          <span
                            style={{
                              color: "blue",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                            onClick={() => handlePrev(2)}
                          >
                            Fill your Info
                          </span>
                          {">"}
                          <span
                            style={{
                              color: "blue",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                            onClick={() => handlePrev(1)}
                          >
                            Event Details
                          </span>
                          {">"}
                          <span style={{ textDecoration: "underline" }}>
                            Select Booking Date & Time
                          </span>
                        </>
                      )}
                      {currentStep === 4 && (
                        <>
                          <span
                            style={{
                              color: "blue",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                            onClick={() => handlePrev(3)}
                          >
                            Fill your Info
                          </span>
                          {">"}
                          <span
                            style={{
                              color: "blue",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                            onClick={() => handlePrev(2)}
                          >
                            Event Details
                          </span>
                          {">"}
                          <span
                            style={{
                              color: "blue",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                            onClick={() => handlePrev(1)}
                          >
                            Select Booking Date & Time
                          </span>
                          {">"}
                          <span style={{ textDecoration: "underline" }}>
                            Terms & Payment
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div>{renderStep()}</div>
                  <div
                    className={`mx-5 my-3 d-flex ${
                      currentStep === 4
                        ? "justify-content-center"
                        : "justify-content-between"
                    }`}
                  >
                    {/* <button
                      className={`btn btn-primary position-absolute ${
                        currentStep !== 1 ? "d-block" : "d-none"
                      }`}
                      onClick={() => handlePrev(1)}
                    >
                      Prev
                    </button> */}
                    <h5
                      className="mt-2 fw-bolder"
                      // style={{ left: "35%" }}
                    >
                      Powered by My Sikh Community
                    </h5>
                    {
                      currentStep === 1 || currentStep === 2 ? (
                        <button
                          className="btn btn-primary"
                          onClick={() => handleNext(1)}
                        >
                          Next
                        </button>
                      ) : (
                        currentStep === 3 && (
                          <Button color="success" onClick={() => handleNext(1)}>
                            Continue to Payment
                          </Button>
                        )
                      )
                      // : (
                      //   <Button color="danger" onClick={handleSubmit}>
                      //     Submit
                      //   </Button>
                      // )
                    }
                    {/* <div
                      className={`d-flex align-items-center ${
                        currentStep === 4
                      }`}
                      style={{ marginTop: "45px" }}
                    >
                      <h5 className="mt-2 fw-bolder">
                        Powered by My Sikh Community
                      </h5>
                      <Button color="danger" onClick={handleSubmit}>
                        Submit
                      </Button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="buttons">
        {/* <div>
          {currentStep > 1 && (
            <button
              onClick={() => setCurrentStep((prev) => prev - 1)}
              className="btn btn-primary px-4"
            >
              Previous
            </button>
          )}
        </div>
        <div>
          {currentStep < 3 && (
            <button
              onClick={() => setCurrentStep((prev) => prev + 1)}
              className="btn btn-primary px-4"
            >
              Next
            </button>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default BookGurdwara;
