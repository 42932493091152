import React, { useEffect } from "react";
import {
  fetchMyNeedsOrRequests,
  fetchMyServices,
} from "../../../components/Header/Data";
import BwvList from "../../../components/Header/WeddingVendors/BwvList";
import LcaBox from "../../../components/Header/LocalCummunityAds/LcaBox";

const PPNeeds = ({ userId }) => {
  const [myNeeds, setMyNeeds] = React.useState([]);
  const [showAll, setShowAll] = React.useState(false);
  const fetchMyNeeds = async () => {
    const reqData = {
      createdBy: userId,
    };
    const fetchNeeds = await fetchMyNeedsOrRequests(reqData);
    setMyNeeds(fetchNeeds);
  };
  React.useEffect(() => {
    fetchMyNeeds();
  }, [userId]);
  const recentNeeds = myNeeds.slice(-2);
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };
  return (
    <div>
      <h6>Things I'm looking for Or Needing (Somthing I Need)</h6>
      <div className="people-need">
        {showAll
          ? myNeeds.map((ad) => (
              <div key={ad.Id}>
                <p className="mb-1" style={{ fontSize: "medium" }}>
                  {ad?.FullName}
                </p>
                <LcaBox ad={ad} />
              </div>
            ))
          : recentNeeds.map((ad) => (
              <div key={ad.Id}>
                <p className="mb-1" style={{ fontSize: "medium" }}>
                  {ad?.FullName}
                </p>
                <LcaBox ad={ad} />
              </div>
            ))}
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button onClick={toggleShowAll}>
          {showAll ? "Show Less" : "Show More..."}
        </button>
      </div>
    </div>
  );
};

export default PPNeeds;
