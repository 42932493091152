import React from "react";
import { useSelector } from "react-redux";
import axiosClient from "../../../axios-client";
import userIcon from "../../../assets/images/ext/user-profile.jpg";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { Button } from "reactstrap";
import { getAmount } from "../../GlobalFunctions/Global";
import { toast } from "react-toastify";
import { FadeLoader } from "react-spinners";

const MyPurchages = () => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";
  const [myPurchages, setMyPurchages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const fetchMyPurchages = async () => {
    setLoading(true);
    const req = {
      type: "customer",
    };
    try {
      const response = await axiosClient.put(
        `item/getAllItemOrder/${user.UserID}`,
        req
      );

      setMyPurchages(response.data.itemList);
    } catch {
      toast.error("Cant fetch");
    }
    setLoading(false);
  };
  React.useEffect(() => {
    fetchMyPurchages();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "50px",
        }}
      >
        <FadeLoader color="#36d7b7" />
      </div>
    );
  }

  if (!myPurchages || myPurchages.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "50px",
        }}
      >
        No Purchages
      </div>
    );
  }

  return (
    <div
      className="d-flex flex-wrap p-5"
      style={{
        whiteSpace: "nowrap",
      }}
    >
      {myPurchages &&
        myPurchages.map((sell) => (
          <Card sx={{ width: 200, margin: "4px 4px" }} key={sell.id}>
            <CardMedia
              component="img"
              height="140"
              image={sell.image && sell.image !== "" ? sell.image : userIcon} // Adjust to your item image URL field
              alt={sell.name} // Adjust to your item title
            />
            <CardContent className="p-2">
              <Typography
                gutterBottom
                variant="body1"
                component="div"
                noWrap
                className="d-flex justify-content-between"
              >
                <span
                  style={{
                    color: "black",
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                >
                  {sell.itemname.length > 15
                    ? `${sell.itemname.slice(0, 15)}...`
                    : sell.itemname}
                </span>
                <span
                  className={`px-2 text-center text-white ${
                    sell.order_status === `COMPLETED`
                      ? `bg-success`
                      : `bg-warning`
                  }`}
                  style={{
                    fontSize: "8px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {sell.order_status || "PENDING"}
                </span>
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", marginBottom: "10px" }}
              >
                Price: ${getAmount(sell.price, sell.delivery_option)}
              </Typography>
              <Typography variant="body2" sx={{ marginBottom: "10px" }}>
                Address: {sell.address || "PENDING"}
              </Typography>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              ></div>
            </CardContent>
          </Card>
        ))}
    </div>
  );
};

export default MyPurchages;
