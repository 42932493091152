import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosClient from "../../axios-client";
import userIcon from "../../assets/images/ext/user-profile.jpg";
import ItemCard from "./ItemCards";
import { useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
import PPItemsForSale from "./PeopleProfileFiles/PPItemsForSale";
import PPServices from "./PeopleProfileFiles/PPServices";
import PPNeeds from "./PeopleProfileFiles/PPNeeds";

const PeopleProfileItems = ({ userDetails, userId }) => {
  const islogin = useSelector((state) => state.user.isLogin);
  const userinfo = useSelector((state) => state.user.userInfo);
  const user = islogin ? JSON.parse(userinfo) : null;
  const isAdmin = user && user.Role === "admin";
  const [profileItems, setProfileItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllIfs = async () => {
    console.log("object");
    setLoading(true);
    try {
      const response = await axiosClient.get(`item/getItemById/${userId}`);
      if (response.data.statusCode === 200) {
        let items = response.data.itemList;
        setProfileItems(items);
      } else {
        toast.error(`${response.data.message} ${response.data.statusCode}`);
      }
    } catch (error) {
      throw new Error("Error getting items");
    }
    setLoading(false);
  };
  useEffect(() => {
    if (userId) {
      getAllIfs();
    }
  }, [userId]);
  return (
    <div>
      <PPItemsForSale
        loading={loading}
        profileItems={profileItems}
        setProfileItems={setProfileItems}
        getAllIfs={getAllIfs}
      />
      <PPServices userId={userId} />
      <PPNeeds userId={userId} />
    </div>
  );
};

export default PeopleProfileItems;
