import { Box, Button, Modal } from "@mui/material";
import React, { useState } from "react";

const StartZoomVendorModal = ({ open, handleClose, link }) => {
  const [isZoomStarted, setIsZoomStarted] = useState(false);
  console.log(link, "link");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleStartZoomCall = () => {
    if (link) {
      setIsZoomStarted(true); // Set state to show the iframe
    } else {
      alert("No Zoom link provided.");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {!isZoomStarted ? (
          <Button onClick={handleStartZoomCall}>Start Zoom Call</Button>
        ) : (
          <iframe
            src={link}
            width="100%"
            height="100%"
            frameBorder="0"
            allow="camera; microphone; fullscreen; autoplay"
            title="Zoom Call"
          />
        )}
      </Box>
    </Modal>
  );
};

export default StartZoomVendorModal;
