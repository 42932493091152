import React from "react";
import { InlineWidget } from "react-calendly";
import CalendarTimeSlotSelector from "./CalenderTimeSlot";
const BookGurdwaraStep3 = () => {
  return (
    // <div>
    //   <div className="d-flex align-items-center justify-content-center">
    //     <div
    //       className="page1"
    //       style={{
    //         height: currentStep === 3 || currentStep === 4 ? "750px" : "405px",
    //       }}
    //     >
    //       <div className="d-flex justify-content-between mx-5 my-4">
    //         <div className="fw-bold fs-5">
    //           <span
    //             onClick={() => setCurrentStep((prev) => prev - 2)}
    //             className="text-primary"
    //             style={{ cursor: "pointer" }}
    //           >
    //             Section1
    //           </span>
    //           &gt;
    //           <span
    //             onClick={() => setCurrentStep((prev) => prev - 1)}
    //             className="text-primary"
    //             style={{ cursor: "pointer" }}
    //           >
    //             Section2
    //           </span>
    //           &gt;
    //           <span className="text-decoration-underline">Section3</span>
    //         </div>
    //         <button
    //           className="btn btn-primary"
    //           onClick={() => setCurrentStep((prev) => prev + 1)}
    //         >
    //           Next
    //         </button>
    //       </div>
    <div className="mx-5 my-4">
      <InlineWidget url="https://calendly.com/provider-wed2024" />
      {/* <CalendarTimeSlotSelector /> */}
    </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default BookGurdwaraStep3;
