import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../SideBar";
import { Row, Col, InputGroup, Input, InputGroupText } from "reactstrap";
import { Button } from "reactstrap";
import { BiPlusCircle, BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners"; // Import FadeLoader
import {
  fetchAllServices,
  fetchMyNeedsOrRequests,
  fetchUserDetails,
} from "../../../components/Header/Data";
import "../DashboardPages.css";
import AllNeeds from "./AllNeeds";
import RecentEngagedNeeds from "./RecentEngagedNeeds";
import bids from "../../../assets/images/get-bids.png";
import { setAllNeeds, setMyNeeds } from "../../../redux/slices/MyNeedsSlice";
import Bizz_Listings from "./Bizz_Listings";

const MyNeedsOrRequests = () => {
  const dispatch = useDispatch();
  const [allServices, setAllServices] = useState([]);
  const [myServices, setMyServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const userinfo = useSelector((state) => state.user.userInfo);
  const islogin = useSelector((state) => state.user.isLogin);
  const { allNeeds, myNeeds } = useSelector((state) => state.myNeeds);
  const user = islogin ? JSON.parse(userinfo) : null;
  const [userDetails, setUserDetails] = useState([]);
  const [activeTab, setActiveTab] = useState("AllNeeds");
  const isAdmin = user && user.Role === "admin"; // Check if user is admin
  const [search, setSearch] = useState("");
  let engagedNeeds = [];
  let bizzNeeds = [];
  let active = [];
  engagedNeeds = allNeeds.filter((need) => need.engagedCouple === 1);
  bizzNeeds = allNeeds.filter(
    (ad) => ad?.bizzValue === 1 || ad?.bizzValue === 2 || ad?.bizzValue === 3
  );
  active = allServices.filter((ad) => ad.isactive === "3");
  useSelector((state) => state.lcAds);

  const fetchData = async () => {
    setIsLoading(true);
    if (islogin && user.RoleId === 1) {
      const data = {
        roleId: user.RoleId,
      };
      const needs = await fetchMyNeedsOrRequests(data);
      dispatch(setAllNeeds(needs));
      dispatch(setMyNeeds(needs));
      const services = await fetchAllServices();
      setAllServices(services);
      setMyServices(services);
    } else {
      const data = {
        createdBy: user.UserID,
      };
      const needs = await fetchMyNeedsOrRequests(data);
      dispatch(setAllNeeds(needs));
      dispatch(setMyNeeds(needs));
    }
    const userData = await fetchUserDetails(user?.UserID);
    setUserDetails(userData);
    setIsLoading(false);
  };
  const handleSearchService = () => {
    if (search?.trim() !== "") {
      const trimmedQuery = search.trim().toLowerCase();
      const filteredServices = myNeeds.filter((need) => {
        return (
          need.Title.toLowerCase().includes(trimmedQuery) ||
          need.Email?.toLowerCase().includes(trimmedQuery) ||
          need.Phone?.toLowerCase().includes(trimmedQuery) ||
          need.FullName?.toLowerCase().includes(trimmedQuery) ||
          need.dialExtension?.toLowerCase().includes(trimmedQuery) ||
          need.categoryName?.toLowerCase().includes(trimmedQuery)
        );
      });
      const filterService = myServices.filter(
        (ad) =>
          ad.listName.toLowerCase().includes(trimmedQuery) ||
          ad.Email?.toLowerCase().includes(trimmedQuery) ||
          ad.Phone?.toLowerCase().includes(trimmedQuery) ||
          ad.FullName?.toLowerCase().includes(trimmedQuery) ||
          ad.dialExtension?.toLowerCase().includes(trimmedQuery) ||
          ad.categoryName?.toLowerCase().includes(trimmedQuery)
      );
      setAllServices(filterService);
      dispatch(setAllNeeds(filteredServices));
    } else {
      fetchData();
    }
  };
  useEffect(() => {
    setSearch("");
  }, [activeTab]);
  useEffect(() => {
    handleSearchService();
  }, [search]);
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <section className="home-after-login bg-white">
      <div className="container">
        <Row>
          <Col sm={3}>
            <Sidebar />
          </Col>
          <Col sm={9} className="requests-container position-relative ">
            {isLoading && (
              <FadeLoader // FadeLoader component
                css={{ margin: "0 auto" }}
                color={"#36D7B7"}
                loading={isLoading}
                className="position-absolute top-50 start-50 translate-middle"
              />
            )}
            {myNeeds.length > 0 ? (
              <div>
                <Row className="w-100">
                  <Col sm={12} md={6} lg={6} xs={12} className="m-0 py-1">
                    {isAdmin && (
                      <InputGroup>
                        <Input
                          type="search"
                          placeholder="Search by Ad Name, Email, Phone Number, Extension, etc..."
                          onChange={(e) => setSearch(e.target.value)}
                          value={search}
                        />
                        <InputGroupText>
                          <BiSearch />
                        </InputGroupText>
                      </InputGroup>
                    )}
                  </Col>

                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    xs={12}
                    className="m-0 py-1 text-end"
                  >
                    <Button
                      type="button"
                      className="btn  btn-sm btn-danger m-1"
                      onClick={() => {
                        navigate("/needs-requests/request");
                      }}
                    >
                      <BiPlusCircle /> Add New
                    </Button>
                    {userDetails.EngagedCoupled === 1 && (
                      <Button
                        type="button"
                        className="btn btn-sm btn-danger text-nowrap"
                        onClick={() => {
                          navigate("/needs-requests/request");
                        }}
                      >
                        <BiPlusCircle /> Engaged Couple Post Your Vendor Need
                      </Button>
                    )}
                  </Col>
                </Row>
                <div
                  className=" col-lg-6 d-flex row my-3 wc_ib-tabs w-100"
                  style={{ marginLeft: "0px" }}
                >
                  <h5
                    className={`col-sm-4 ${
                      activeTab === "AllNeeds" ? "active " : ""
                    } mx-0`}
                    onClick={() => {
                      setActiveTab("AllNeeds");
                    }}
                    style={{
                      textDecoration: activeTab !== "AllNeeds" && "none", // Apply underline when active
                      cursor: "pointer", // Add cursor style to indicate clickable
                      width: isAdmin ? "34%" : "50%",
                    }}
                  >
                    All Needs
                  </h5>
                  <h5
                    className={`col-sm-4 ${
                      activeTab === "RecentEngaged" ? "active" : ""
                    } mx-0`}
                    onClick={() => {
                      setActiveTab("RecentEngaged");
                    }}
                    style={{
                      textDecoration: activeTab !== "AllNeeds" && "none", // Apply underline when active
                      cursor: "pointer", // Add cursor style to indicate clickable
                      width: isAdmin ? "33%" : "50%",
                    }}
                  >
                    Recently Engaged Needs
                  </h5>
                  {isAdmin && (
                    <h5
                      className={`col-sm-4 ${
                        activeTab === "Bizz_Listings" ? "active" : ""
                      } mx-0`}
                      onClick={() => {
                        setActiveTab("Bizz_Listings");
                        fetchData();
                      }}
                      style={{
                        textDecoration: activeTab !== "AllNeeds" && "none", // Apply underline when active
                        cursor: "pointer", // Add cursor style to indicate clickable
                        width: "33%",
                      }}
                    >
                      Bizz Listings
                    </h5>
                  )}
                </div>
                <div className="tab-content">
                  {activeTab === "AllNeeds" && (
                    <div>
                      <AllNeeds allNeeds={allNeeds} fetchData={fetchData} />
                    </div>
                  )}
                  {activeTab === "RecentEngaged" && (
                    <div>
                      <RecentEngagedNeeds
                        engagedNeeds={engagedNeeds}
                        fetchData={fetchData}
                      />
                    </div>
                  )}
                  {activeTab === "Bizz_Listings" && !isLoading && (
                    <div>
                      <Bizz_Listings
                        bizzNeeds={bizzNeeds}
                        isLoading={isLoading}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              !isLoading && (
                <div className="ads">
                  <img
                    src={bids}
                    alt="img"
                    style={{ width: "10rem", marginBottom: "20px" }}
                  />
                  <h4>Do you have a specific Need/Request?</h4>
                  <p>
                    Post an Ad of your need & reach the local Indian Community
                  </p>
                  <Button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      navigate("/needs-requests/request");
                    }}
                  >
                    <BiPlusCircle /> Post Your Ad
                  </Button>
                </div>
              )
            )}
          </Col>
        </Row>
      </div>
    </section>
  );
};
export default MyNeedsOrRequests;
